import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchProviders,
  setLoginUrl,
  fetchProviderLogin,
  setSearchValue,
} from 'store/actions/bankAccountsActions'
import {
  TextInput,
  MultipleChoiceItem,
} from '@qred/ui-components'
import _compact from 'lodash.compact'
import useTranslate from 'hooks/useTranslate'
import { mapValidLanguageNAG } from 'helpers/bank-accounts'
import { QRED_PRIORITY_BANKS } from 'helpers/qred'
import parseOrganizationNumber from 'helpers/parsers/parseOrganizationNumber'
import {
  ConnectBankRecordsContainer,
  ConnectBankRecordsChooseBank,
  ConnectBankRecordsSearchInput,
  ConnectBankRecordsProviderContainer,
  ConnectBankRecordsZeroState,
  ConnectBankRecordsIframeContainer,
  ConnectBankRecordsIframe,
} from './ConnectBankRecords.css'

const ConnectBankRecords = () => {
  const dispatch = useDispatch()
  const t = useTranslate()
  const [frameSize, setFrameSize] = useState('')
  const locale = useSelector(state => state.intl.locale)
  const bankAccounts = useSelector(state => state.bankAccounts)
  const organizationNumber = useSelector(
    state => state.application.loanDetails.organizationNumberManual
    || state.application.loanDetails.organizationNumber.value,
  )
  const qredLocale = locale.replace('en-', '')

  const receiveMessage = event => {
    if (event.data.type === 'iframe_reload') {
      dispatch(setLoginUrl(''))
      setFrameSize('')
    }

    if (event.data.type === 'iframe_resize') {
      setFrameSize(event.data.height)
    }
  }

  const splicInPlace = (array, condition) => {
    /* eslint-disable no-plusplus */
    const filtered = []
    let iOut = 0
    for (let i = 0; i < array.length; i++) {
      if (condition(array[i])) {
        filtered.push(array[i])
      } else {
        array[iOut++] = array[i]
      }
    }
    array.length = iOut
    return filtered
    /* eslint-enable no-plusplus */
  }

  const orderProviders = providers => {
    const priorityBanks = QRED_PRIORITY_BANKS[qredLocale]
    const orderedProviders = splicInPlace(providers, provider => priorityBanks.includes(provider.key))
    const testProviders = splicInPlace(providers, provider => provider.key.includes('TestDataBank'))

    testProviders.sort((a, b) => {
      return parseInt(a.key.substring(12), 10) - parseInt(b.key.substring(12), 10)
    })

    return orderedProviders.concat(providers, testProviders)
  }

  const filterProviders = provider => {
    try {
      const trimmedValue = bankAccounts.searchValue.replace(/\s+/g, '').toLowerCase()
      const trimmedBankName = provider.name.replace(/\s+/g, '').toLowerCase()
      const rgx = new RegExp(`${trimmedValue}.*`)

      return trimmedBankName.match(rgx)
    } catch (e) {
      return false
    }
  }

  const handleSelectProvider = async providerId => {
    let redirectUrl
    switch (qredLocale) {
      case 'nl':
        redirectUrl = process.env.BANKACCOUNTGATEWAY_API_REDIRECT_URL_QELD
        break
      default:
        redirectUrl = process.env.BANKACCOUNTGATEWAY_API_REDIRECT_URL_QRED
        break
    }

    const data = {
      providerId,
      userId: `UICN${parseOrganizationNumber(organizationNumber)}${qredLocale.toUpperCase()}`,
      language: mapValidLanguageNAG[locale],
      redirectUrl: redirectUrl.replace('{LOCALE}', locale),
    }
    dispatch(fetchProviderLogin(data))
  }

  const handleSearchInput = e => {
    dispatch(setSearchValue(e.target.value))
  }

  useEffect(() => {
    window.addEventListener('message', receiveMessage)
    dispatch(fetchProviders(qredLocale))

    return () => window.removeEventListener('message', receiveMessage)
  }, [qredLocale])

  let providers = bankAccounts.providers.map(provider => {
    if (filterProviders(provider)) {
      return (
        <MultipleChoiceItem
          key={provider.id}
          label={provider.name}
          value={provider.name}
          onClick={() => handleSelectProvider(provider.id)}
        />
      )
    }
    return null
  })

  providers = _compact(providers)
  providers = orderProviders(providers)

  return (
    <ConnectBankRecordsContainer>
      {!bankAccounts.loginUrl && (
        <ConnectBankRecordsChooseBank>
          <ConnectBankRecordsProviderContainer>
            {providers}
            {
              providers.length < 1 && (
                <ConnectBankRecordsZeroState>
                  { t('connectBankRecords.searchZeroState') }
                </ConnectBankRecordsZeroState>
              )
            }
          </ConnectBankRecordsProviderContainer>
          <ConnectBankRecordsSearchInput>
            <TextInput
              value={bankAccounts.searchValue}
              onChange={handleSearchInput}
              label={t('connectBankRecords.searchPlaceholder')}
              grey
              searchIcon
            />
          </ConnectBankRecordsSearchInput>
        </ConnectBankRecordsChooseBank>
      )}
      {bankAccounts.loginUrl && (
        <ConnectBankRecordsIframeContainer size={frameSize}>
          <ConnectBankRecordsIframe
            id="BAGiframe"
            title="Nordic API Gateway"
            src={bankAccounts.loginUrl}
            frameBorder="0"
            style={{ paddingBottom: '35px' }}
          />
        </ConnectBankRecordsIframeContainer>
      )}
    </ConnectBankRecordsContainer>
  )
}

export default ConnectBankRecords
