import styled from 'styled-components'

export const BankAccountResponseContainer = styled.div`
  padding-bottom: 70px;
  padding-top: 70px;
`

export default {
  BankAccountResponseContainer,
}
