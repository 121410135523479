import styled, { css } from 'styled-components'
import mq from 'helpers/mq'

const ApplicationContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  width: 100%;
`

const ApplicationContent = styled.div`
  padding: 70px 12px 48px;
`

const ApplicationStepsHeader = styled.div`
  display: flex;
  padding: 10px 16px;

  i {
    &:before {
      font-size: 1.1rem;
      transform: rotate(180deg);
    }
  }
  ${mq.desktop`
    align-self: center;
    width: 550px;
    margin-left: -20px;
  `};
`

const ApplicationStepsHeaderBackButton = styled.div`
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  ${props => props.disabled && css`
    opacity: 0.2;
  `}
`

const ApplicationStepsHeaderSteps = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`

const ApplicationStepsHeaderStep = styled.div`
  align-items: center;
  color: #8B8B8B;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100px;
  padding-bottom: 8px;
  border-bottom: 2px solid #989898;

  span {
    font-size: 11px;
    line-height: 14px;
  }

  ${props => props.selected && css`
    color: #4C53FF;
    font-weight: 700;
    border-color: #4C53FF;
  `}

  ${props => props.validated && css`
    &:after {
      // background-image: url('https://s3.eu-central-1.amazonaws.com/static.qred.com/sv-se/bilder/checkbox.svg');
    }
  `}

  ${props => props.clickable && css`
    cursor: pointer;
  `}
`

const ApplicationFlowList = styled.div`
  overflow: hidden;
  padding: 0 4px;
  position: relative;
  width: 100%;

  .ApplicationFlowListGroup {
    display: flex;
    position: absolute;
    transition: transform .5s ease-in-out;

    .ApplicationFlowListItem {
      margin-right: 32px;
      width: calc(100vw - 32px);
    }
  }
  ${mq.desktop`
    .ApplicationFlowListGroup {
      .ApplicationFlowListItem {
        max-width: 1290px;
      }
    }
  `};
`

const ApplicationFlowListItemContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  ${mq.desktop`
    max-width: 450px;
    margin: 0 auto;
  `};
`

const ApplicationLoading = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`

const ApplicationLoadingIcon = styled.div`
  align-items: center;
  background-color: #4C53FF;
  border-radius: 50%;
  display: flex;
  height: 64px;
  justify-content: center;
  width: 64px;
`

export {
  ApplicationContainer,
  ApplicationContent,
  ApplicationStepsHeader,
  ApplicationStepsHeaderBackButton,
  ApplicationStepsHeaderSteps,
  ApplicationStepsHeaderStep,
  ApplicationFlowList,
  ApplicationFlowListItemContent,
  ApplicationLoading,
  ApplicationLoadingIcon,
}
