import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import compose from 'recompose/compose'
import useResizeObserver from 'use-resize-observer'
import { Helmet } from 'react-helmet-async'

import useTranslate from 'hooks/useTranslate'
import { setCompanyInfo } from 'store/actions/application'
import { setContainerHeight } from 'store/actions/ui'

import {
  FileUploader,
  Icon,
  LoadingButton,
  TextArea,
  TextInput,
} from '@qred/ui-components'

import {
  CompanyInfoContainer,
  CompanyInfoTitle,
  CompanyInfoOptional,
  CompanyInfoError,
  CompanyInfoUploadDocsSection,
  CompanyInfoUploadDocsTitle,
  CompanyInfoUploadDocsDescription,
  CompanyInfoWebsiteSection,
  CompanyInfoWebsiteTitle,
  CompanyInfoMessageSection,
  CompanyInfoMessageTitle,
} from './CompanyInfo.css'

const CompanyInfo = ({
  companyInfo,
  currentStep,
  handleNextStep,
  isUpdating,
  isRLC,
}) => {
  const dispatch = useDispatch()
  const t = useTranslate()
  const {
    website,
    message,
    documents,
  } = companyInfo
  const [errors, setErrors] = useState({})
  const hasErrors = useMemo(
    () => !!Object.values(errors).filter(e => e).length,
    [errors],
  )
  const [ref, , height] = useResizeObserver()
  const isActive = currentStep === 2

  const handleChangeWebsite = e => {
    if (errors.website) {
      setErrors({
        ...errors,
        website: null,
      })
    }

    dispatch(setCompanyInfo({
      website: e.target.value,
    }))
  }

  const handleValidateWebsite = e => {
    const { validity: { valid }, value } = e.target

    if (value && !valid) {
      setErrors({
        ...errors,
        website: `${t('application.companyInfo.website.error')}`,
      })
    }
  }

  const handleChangeMessage = e => {
    dispatch(setCompanyInfo({
      message: e.target.value,
    }))
  }

  const handleChangeDocuments = useCallback(newDocuments => {
    setErrors({
      ...errors,
      maxFileSizeExceeded: null,
      extensionDisallowed: null,
    })
    if (newDocuments.length !== documents.length) {
      dispatch(setCompanyInfo({
        documents: newDocuments,
      }))
    }
  }, [])

  const handleMaxFileSizeExceeded = () => {
    setErrors({
      ...errors,
      maxFileSizeExceeded: t('application.fieldErrors.fileTooBigError', {
        emailLink: <a style={{ display: 'contents', color: 'red' }} href={`mailto:${t('contact.email.raw')}`}>{t('contact.email.formatted')}</a>,
      }),
    })
  }

  const handleExtensionNotAllowed = () => {
    setErrors({
      ...errors,
      extensionDisallowed: t('application.fieldErrors.fileExtensionNotAllowed', {
        emailLink: <a style={{ display: 'contents', color: 'red' }} href={`mailto:${t('contact.email.raw')}`}>{t('contact.email.formatted')}</a>,
      }),
    })
  }

  useEffect(() => {
    if (isActive) {
      dispatch(setContainerHeight(height))
    }
  }, [currentStep, height])

  return (
    <>
      {isActive && (
        <Helmet>
          <title>{t('application.companyInfo.head.title')}</title>
          <meta name="description" content={t('application.companyInfo.head.description')} />
        </Helmet>
      )}

      <CompanyInfoContainer data-cy="application-flow-step-4" ref={ref}>
        <CompanyInfoTitle>{ t('application.companyInfo.title') }</CompanyInfoTitle>

        <CompanyInfoUploadDocsSection>
          <CompanyInfoUploadDocsTitle>{ t('application.companyInfo.documents.title') }</CompanyInfoUploadDocsTitle>
          <CompanyInfoOptional>{ t('application.optionalText') }</CompanyInfoOptional>
          <CompanyInfoUploadDocsDescription>{ t('application.companyInfo.documents.description') }</CompanyInfoUploadDocsDescription>
          <FileUploader
            secondary
            drop
            extensionDisallowed={['exe', 'dmg', 'zip', 'rar']}
            onMaxFileSizeExceeded={() => handleMaxFileSizeExceeded()}
            onExtensionNotAllowed={() => handleExtensionNotAllowed()}
            label={t('application.companyInfo.documents.inputLabel')}
            dragLabel={t('application.companyInfo.documents.dragLabel')}
            fileListLabel={t('application.companyInfo.documents.fileListLabel')}
            onChange={handleChangeDocuments}
            data-cy="company-info-button-file-upload"
          />
          {errors.maxFileSizeExceeded && (
            <CompanyInfoError>
              <Icon name="warning" />
              {errors.maxFileSizeExceeded}
            </CompanyInfoError>
          )}
          {errors.extensionDisallowed && (
            <CompanyInfoError>
              <Icon name="warning" />
              {errors.extensionDisallowed}
            </CompanyInfoError>
          )}
        </CompanyInfoUploadDocsSection>

        {!isRLC && (
          <>
            <CompanyInfoWebsiteSection>
              <CompanyInfoWebsiteTitle>{ t('application.companyInfo.website.title') }</CompanyInfoWebsiteTitle>
              <CompanyInfoOptional>{ t('application.optionalText') }</CompanyInfoOptional>
              <TextInput
                value={website}
                onBlur={handleValidateWebsite}
                onChange={handleChangeWebsite}
                maxLength="200"
                pattern="^(([Hh][Tt][Tt][Pp][Ss]?:\/\/)?([\w\d\-åäöÅÄÖ]+)(\.[\w]{2,})+).*"
                data-cy="company-info-website"
              />
              {errors.website && (
                <CompanyInfoError>
                  <Icon name="warning" />
                  {errors.website}
                </CompanyInfoError>
              )}
            </CompanyInfoWebsiteSection>
            <CompanyInfoMessageSection>
              <CompanyInfoMessageTitle>{ t('application.companyInfo.message.title') }</CompanyInfoMessageTitle>
              <CompanyInfoOptional>{ t('application.optionalText') }</CompanyInfoOptional>
              <TextArea
                light
                value={message}
                onChange={handleChangeMessage}
                data-cy="company-info-additional-notes"
              />
            </CompanyInfoMessageSection>
          </>
        )}
        <LoadingButton
          disabled={hasErrors && errors.website}
          fullwidth
          isLoading={isUpdating}
          onClick={handleNextStep}
          secondary
          data-cy="company-info-button-continue"
        >
          {t('application.companyInfo.button.finish')}
        </LoadingButton>
      </CompanyInfoContainer>
    </>
  )
}

CompanyInfo.propTypes = {
  companyInfo: PropTypes.shape({
    website: PropTypes.string,
    message: PropTypes.string,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        lastModified: PropTypes.number,
        name: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string,
        webkitRelativePath: PropTypes.string,
      }),
    ),
  }).isRequired,
  currentStep: PropTypes.number.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isRLC: PropTypes.bool.isRequired,
}

export default compose(
  connect(
    state => ({
      companyInfo: state.application.companyInfo,
      currentStep: state.application.currentStep,
      isUpdating: state.application.isUpdating,
      isRLC: state.application.isRLC,
    }),
  ),
)(CompanyInfo)
