import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Icon,
  LoadingSpinner,
} from '@qred/ui-components'
import useTranslate from 'hooks/useTranslate'
import {
  ResponseMessageContainer,
  ResponseMessageIconWrapper,
  ResponseMessageBox,
  ResponseMessageTitle,
  ResponseMessageText,
} from './ResponseMessage.css'

const ResponseMessage = ({
  type,
  onClick,
}) => {
  const t = useTranslate()
  let iconType = ''
  let title = ''
  let text = ''
  let buttonLabel = ''

  switch (type) {
    case 'success':
      iconType = 'checked'
      title = t('connectBankRecords.response.success.title')
      text = t('connectBankRecords.response.success.text')
      break
    case 'error':
      iconType = 'close'
      title = t('connectBankRecords.response.error.title')
      text = t('connectBankRecords.response.error.text')
      buttonLabel = t('connectBankRecords.response.error.buttonText')
      break
    case 'loading':
      iconType = 'loading'
      title = t('connectBankRecords.response.loading.title')
      text = t('connectBankRecords.response.loading.text')
      break
    default:
      iconType = 'loading'
      title = t('connectBankRecords.response.loading.title')
      text = t('connectBankRecords.response.loading.text')
  }

  return (
    <ResponseMessageContainer type={type}>
      <ResponseMessageIconWrapper>
        {
          (iconType === 'loading') ? (
            <LoadingSpinner
              size="small"
              white
            />
          ) : (
            <Icon name={iconType} />
          )
        }
      </ResponseMessageIconWrapper>
      <ResponseMessageBox>
        <ResponseMessageTitle>{title}</ResponseMessageTitle>
        <ResponseMessageText>{text}</ResponseMessageText>
        {onClick && (
          <Button
            tertiary
            onClick={onClick}
          >
            {buttonLabel}
          </Button>
        )}
      </ResponseMessageBox>
    </ResponseMessageContainer>
  )
}

ResponseMessage.defaultProps = {
  type: 'error',
  onClick: null,
}

ResponseMessage.propTypes = {
  type: PropTypes.oneOf([
    'success',
    'error',
    'loading',
    'cancel',
  ]),
  onClick: PropTypes.func,
}

export default ResponseMessage
