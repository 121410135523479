import Application from 'routes/Application'
import ApplicationThankYou from 'routes/ApplicationThankYou'
import BankAccountResponse from 'routes/BankAccountResponse'
import NotFound from 'routes/NotFound'

export default [
  {
    path: '/en-se/applications/thank-you',
    exact: true,
    component: ApplicationThankYou,
  },
  {
    path: '/se/applications/tack',
    exact: true,
    component: ApplicationThankYou,
  },
  {
    path: '/:locale/applications/:applicationId(\\w{2}[a-z0-9]{8}\\-[a-z0-9]{4}\\-[a-z0-9]{4}\\-[a-z0-9]{4}\\-[a-z0-9]{12})/:pageName?',
    component: Application,
  },
  {
    path: '/:locale/bank-account/response',
    component: BankAccountResponse,
  },
  {
    component: NotFound,
  },
]
