import initialState from 'store/initialState'
import {
  BANK_ACCOUNT_SET_SEARCH_VALUE,
  BANK_ACCOUNT_SET_LOGIN_URL,
  BANK_ACCOUNT_SET_PROVIDERS,
} from '../constants/bankAccounts'

const bankAccountsReducer = (state = initialState.bankAccounts, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_SET_LOGIN_URL:
      return {
        ...state,
        loginUrl: action.url,
      }
    case BANK_ACCOUNT_SET_PROVIDERS:
      return {
        ...state,
        providers: action.providers,
      }
    case BANK_ACCOUNT_SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.searchValue,
      }
    default:
      return state
  }
}

export default bankAccountsReducer
