import styled from 'styled-components'

const CompanyInfoContainer = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
`

const CompanyInfoTitle = styled.h3`
  margin-bottom: 70px;
`

const CompanyInfoOptional = styled.small`
  color: #808080;
  font-weight: 300;
  margin-bottom: 8px;
`

const CompanyInfoError = styled.div`
  align-items: center;
  color: #ED4848;
  display: flex;
  font-size: .875rem;
  margin-top: 10px;

  i {
    font-size: 1.25rem;
    margin-right: 8px;
  }
`

const CompanyInfoUploadDocsSection = styled.div`
  margin-bottom: 100px;
`

const CompanyInfoUploadDocsTitle = styled.h4`
  margin-bottom: 4px;
`

const CompanyInfoUploadDocsDescription = styled.p`
  margin-bottom: 30px;
  margin-top: 10px;
`

const CompanyInfoWebsiteSection = styled.div`
  margin-bottom: 80px;
`

const CompanyInfoWebsiteTitle = styled.h4`
  margin-bottom: 4px;
`

const CompanyInfoMessageSection = styled.div`
  margin-bottom: 70px;

  textarea {
    border: 1px solid #bababa;
    margin-top: 22px;
  }
`

const CompanyInfoMessageTitle = styled.h4`
  margin-bottom: 4px;
`

export {
  CompanyInfoContainer,
  CompanyInfoTitle,
  CompanyInfoOptional,
  CompanyInfoError,
  CompanyInfoUploadDocsSection,
  CompanyInfoUploadDocsTitle,
  CompanyInfoUploadDocsDescription,
  CompanyInfoWebsiteSection,
  CompanyInfoWebsiteTitle,
  CompanyInfoMessageSection,
  CompanyInfoMessageTitle,
}
