import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@qred/ui-components'

import useTranslate from 'hooks/useTranslate'

import {
  FaqAnswer,
  FaqContainer,
  FaqItem,
  FaqItemToggle,
  FaqItems,
  FaqQuestion,
  FaqTitle,
} from './Faq.css'

const Item = ({
  question,
  answer,
}) => {
  const ref = useRef()
  const [height, setHeight] = useState(0)
  const [isOpen, toggle] = useState(false)

  useEffect(() => {
    setHeight(isOpen ? ref.current.scrollHeight : 0)
  }, [isOpen])

  return (
    <FaqItem isOpen={isOpen}>
      <FaqQuestion onClick={() => toggle(!isOpen)}>
        {question}
        <FaqItemToggle>
          <Icon name="close" />
        </FaqItemToggle>
      </FaqQuestion>
      <FaqAnswer ref={ref} style={{ height: `${height}px` }}>{answer}</FaqAnswer>
    </FaqItem>
  )
}

Item.propTypes = {
  answer: PropTypes.node.isRequired,
  question: PropTypes.string.isRequired,
}

const Faq = () => {
  const t = useTranslate()
  const questions = [
    {
      question: t('applicationThankYou.faq.items.1.question'),
      answer: t('applicationThankYou.faq.items.1.answer'),
    },
    {
      question: t('applicationThankYou.faq.items.2.question'),
      answer: t('applicationThankYou.faq.items.2.answer'),
    },
    {
      question: t('applicationThankYou.faq.items.3.question'),
      answer: t('applicationThankYou.faq.items.3.answer', {
        phoneLink: <strong><a href={`tel:${t('contact.phone.raw')}`}>{t('contact.phone.formatted')}</a></strong>,
        emailLink: <strong><a href={`mailto:${t('contact.email.raw')}`}>{t('contact.email.formatted')}</a></strong>,
      }),
    },
  ]

  return (
    <FaqContainer>
      <FaqTitle>{t('applicationThankYou.faq.title')}</FaqTitle>
      <FaqItems>
        {questions.map(q => (
          <Item
            key={q.question}
            question={q.question}
            answer={q.answer}
          />
        ))}
      </FaqItems>
    </FaqContainer>
  )
}

export default Faq
