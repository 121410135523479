import flatten from 'flat'
import messagesEnSe from 'locales/en-se.json'
import messagesSe from 'locales/se.json'
import { setLocale } from 'store/actions/intlActions'

const DEFAULT_LOCALE = 'se'

const AVAILABLE_LOCALES = [
  'se',
  'en-se',
]

// Used as workaround for /se/ since react-intl supports only sv
const LOCALE_MASK = {
  se: 'sv',
  'en-se': 'en-se',
}

const isValidLocale = locale => AVAILABLE_LOCALES.includes(locale)

const initI18n = (locale, store) => {
  /* eslint-disable global-require */
  if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill')
    require('@formatjs/intl-pluralrules/dist/locale-data/sv') // Add locale data for de
  }

  if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill')
    require('@formatjs/intl-relativetimeformat/dist/locale-data/sv') // Add locale data for de
  }
  /* eslint-enable global-require */

  const i18nConfig = {
    locale: isValidLocale(locale) ? LOCALE_MASK[locale] : LOCALE_MASK[DEFAULT_LOCALE],
    messages: {
      sv: flatten(messagesSe),
      'en-se': flatten(messagesEnSe),
    },
  }

  store.dispatch(setLocale(locale))

  return i18nConfig
}


export {
  DEFAULT_LOCALE,
  AVAILABLE_LOCALES,
  LOCALE_MASK,
  initI18n,
  isValidLocale,
}

export default initI18n
