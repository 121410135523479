import styled, { css } from 'styled-components'

const LoanDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const LoanDetailsGreeting = styled.h3`
  margin-bottom: 4px;

`
const LoanDetailsTitle = styled.h3`
  margin-bottom: 50px;
`

const LoanDetailsNationalIdSection = styled.div`
  margin-bottom: 100px;
`

const LoanDetailsNationalIdTitle = styled.h4`

`

const LoanDetailsCompanyLocked = styled.p`
  margin-top: 1rem;
`

const LoanDetailsCompanySection = styled.div`
  margin-bottom: 100px;
`

const LoanDetailsCompanyTitle = styled.h4`
  ${props => props.disabled && css`
    color: #999;
  `}
`

const LoanDetailsCompanyManualTitle = styled.h4`
  margin-top: 40px;
  ${props => props.disabled && css`
    color: #999;
  `}
`

const LoanDetailsPurposeSection = styled.div`
  margin-bottom: 100px;
`

const LoanDetailsPurposeTitle = styled.h4`

`

const LoanDetailsError = styled.div`
  align-items: center;
  color: #ED4848;
  display: flex;
  font-size: .875rem;
  margin-top: 10px;

  i {
    font-size: 1.25rem;
    margin-right: 8px;
  }
`


export {
  LoanDetailsContainer,
  LoanDetailsError,
  LoanDetailsGreeting,
  LoanDetailsTitle,
  LoanDetailsNationalIdSection,
  LoanDetailsNationalIdTitle,
  LoanDetailsCompanyLocked,
  LoanDetailsCompanySection,
  LoanDetailsCompanyTitle,
  LoanDetailsCompanyManualTitle,
  LoanDetailsPurposeSection,
  LoanDetailsPurposeTitle,
}
