import styled from 'styled-components'
import mq from 'helpers/mq'

const AppContainerElement = styled.div`
  position: relative;
  z-index: 99;
  max-width: 100%;
  width: 100%;
  background-color: rgb(248, 248, 248);
  min-height: 100vh;

  ${mq.largeDesktop`
    background-color: #fff;
    max-width: 1300px;
    margin: 0 auto;
  `};
`

const AppContainerSnake = styled.div `
  position: absolute;
  background-image: url(https://static.qred.com/sv-se/bilder/snake-pattern-2.svg);
  background-size: 95% 100%;
  height: 100%;
  width: 100%;
  background-repeat: repeat-x;
`

export {
  AppContainerElement,
  AppContainerSnake,
}
