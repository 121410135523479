import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Icon,
  LoadingSpinner,
  ThemeProvider,
  themes,
} from "@qred/ui-components";

import useTranslate from "hooks/useTranslate";
import {
  handleNextStep,
  initApplication,
  navigateToStep,
} from "store/actions/application";
import Navigation from "components/Navigation";
import ApplicationFinished from "components/ApplicationFinished";
import ApplicationError from "components/ApplicationError";
import LoanDetails from "./components/LoanDetails";
import BankInfo from "./components/BankInfo";
import CompanyInfo from "./components/CompanyInfo";

import {
  ApplicationContainer,
  ApplicationContent,
  ApplicationStepsHeader,
  ApplicationStepsHeaderBackButton,
  ApplicationStepsHeaderSteps,
  ApplicationStepsHeaderStep,
  ApplicationFlowList,
  ApplicationFlowListItemContent,
  ApplicationLoading,
  ApplicationLoadingIcon,
} from "./Application.css";

const Application = ({
  currentStep,
  isLoaded,
  showModal,
  stepOneCompleted,
  containerHeight,
}) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const { applicationId } = useParams();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const steps = [
    {
      id: 0,
      fieldId: "loanDetails",
      component: LoanDetails,
    },
    {
      id: 1,
      fieldId: "bankInfo",
      component: BankInfo,
    },
    {
      id: 2,
      fieldId: "companyInfo",
      component: CompanyInfo,
    },
  ];

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleBackButtonClick = () => {
    if (currentStep === 0) {
      window.location.href = t("links.home.url");
    } else {
      dispatch(navigateToStep(currentStep - 1));
      window.scrollTo(0, 0);
    }
  };

  const groupPosition = (currentStep / steps.length) * 100;

  useEffect(() => {
    console.log(`applicationID: ${applicationId}`);
    dispatch(initApplication());
  }, []);

  return (
    <ThemeProvider theme={themes.swagnes}>
      <ApplicationContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex" />
          <title>{t("application.head.title")}</title>
          <meta
            name="description"
            content={t("application.head.description")}
          />
          <link
            rel="canonical"
            href={
              process.env.ENVIRONMENT === "production"
                ? "https://qred.com/"
                : "https://beta.qred.com/"
            }
          />
          <link rel="icon" type="image/png" href="/favicon.png" />
          <meta name="theme-color" content="#42d984" />
        </Helmet>
        <Navigation toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} />

        {showModal === "application_fatal_error" && (
          <ApplicationError isFixed />
        )}

        {showModal === "application_error" && <ApplicationError />}

        {/* {showModal === "application_finished" && <ApplicationFinished />} */}

        {isLoaded ? (
          <>
            <ApplicationStepsHeader>
              <ApplicationStepsHeaderBackButton
                disabled={currentStep === 0}
                onClick={handleBackButtonClick}
              >
                <Icon name="arrow" />
              </ApplicationStepsHeaderBackButton>
              <ApplicationStepsHeaderSteps>
                <ApplicationStepsHeaderStep
                  validated={stepOneCompleted}
                  clickable
                  selected={currentStep === 0}
                  onClick={() => dispatch(navigateToStep(0))}
                  data-cy="header-nav-step-2"
                >
                  <span>{t("application.stepsHeader.step2")}</span>
                  <span>{t("application.stepsHeader.loanDetails")}</span>
                </ApplicationStepsHeaderStep>
                <ApplicationStepsHeaderStep
                  validated={currentStep > 1}
                  clickable={stepOneCompleted}
                  selected={currentStep === 1}
                  onClick={
                    stepOneCompleted ? () => dispatch(navigateToStep(1)) : null
                  }
                  data-cy="header-nav-step-3"
                >
                  <span>{t("application.stepsHeader.step3")}</span>
                  <span>{t("application.stepsHeader.bankInfo")}</span>
                </ApplicationStepsHeaderStep>
                <ApplicationStepsHeaderStep
                  validated={currentStep > 2}
                  clickable={stepOneCompleted}
                  selected={currentStep === 2}
                  onClick={
                    stepOneCompleted ? () => dispatch(navigateToStep(2)) : null
                  }
                  data-cy="header-nav-step-4"
                >
                  <span>{t("application.stepsHeader.step4")}</span>
                  <span>{t("application.stepsHeader.companyInfo")}</span>
                </ApplicationStepsHeaderStep>
              </ApplicationStepsHeaderSteps>
            </ApplicationStepsHeader>
            <ApplicationContent>
              <ApplicationFlowList style={{ height: `${containerHeight}px` }}>
                <TransitionGroup
                  className="ApplicationFlowListGroup"
                  style={{ transform: `translateX(-${groupPosition}%)` }}
                >
                  {steps.map(({ id, component }) => {
                    const ComponentJSX = component;

                    return (
                      <CSSTransition
                        key={id}
                        timeout={500}
                        classNames="ApplicationFlowListItem"
                      >
                        <div className="ApplicationFlowListItem">
                          <ApplicationFlowListItemContent>
                            <ComponentJSX
                              handleNextStep={() => dispatch(handleNextStep())}
                            />
                          </ApplicationFlowListItemContent>
                        </div>
                      </CSSTransition>
                    );
                  })}
                </TransitionGroup>
              </ApplicationFlowList>
            </ApplicationContent>
          </>
        ) : (
          <ApplicationLoading>
            <ApplicationLoadingIcon>
              <LoadingSpinner size="medium" white />
            </ApplicationLoadingIcon>
          </ApplicationLoading>
        )}
      </ApplicationContainer>
    </ThemeProvider>
  );
};

Application.propTypes = {
  currentStep: PropTypes.number.isRequired,
  showModal: PropTypes.string.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  containerHeight: PropTypes.number.isRequired,
  stepOneCompleted: PropTypes.bool.isRequired,
};

export default compose(
  connect((state) => ({
    currentStep: state.application.currentStep,
    stepOneCompleted: state.application.stepOneCompleted,
    showModal: state.ui.modal,
    isLoaded: state.ui.isAppLoaded,
    containerHeight: state.ui.height,
  }))
)(Application);
