const initialState = {
  auth: {
    isLoggedIn: false,
    isPending: false,
  },
  intl: {
    locale: '',
  },
  bankAccounts: {
    loginUrl: '',
    providers: [],
    searchValue: '',
  },
  application: {
    applicationId: null,
    hasLoadedCompanyEngagements: false,
    hasNationalIdOnLoad: false,
    hasOrganizationOnLoad: false,
    companyList: [],
    isLoaded: false,
    currentStep: 0,
    stepOneCompleted: false,
    applicationStatus: null,
    isUpdating: false,
    isRLC: false,
    name: {
      first: '',
      last: '',
    },
    loanDetails: {
      nationalIdNumber: '',
      organizationNumber: {
        value: '',
      },
      purpose: {
        value: null,
      },
      purposeManual: '',
      organizationNumberManual: '',
    },
    companyInfo: {
      website: '',
      message: '',
      documents: [],
    },
  },
  ui: {
    isAppLoaded: false,
    modal: '',
    height: 0,
  },
}

export default initialState
