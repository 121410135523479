import {
  AUTH_FAILURE,
  AUTH_LOGOUT,
  AUTH_PENDING,
  AUTH_SUCCESS,
} from '../constants/auth'
import initialState from '../initialState'

const intlReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case AUTH_PENDING:
      return {
        ...state,
        isPending: true,
      }
    case AUTH_SUCCESS:
      return {
        ...state,
        error: null,
        isLoggedIn: true,
        isPending: false,
      }
    case AUTH_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoggedIn: false,
        isPending: false,
      }
    case AUTH_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      }
    default:
      return state
  }
}

export default intlReducer
