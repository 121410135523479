import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import useResizeObserver from 'use-resize-observer'
import { setContainerHeight } from 'store/actions/ui'
import { Helmet } from 'react-helmet-async'

import {
  Button,
} from '@qred/ui-components'
import useTranslate from 'hooks/useTranslate'
import ConnectBankRecords from '../ConnectBankRecords'
import {
  BankInfoContainer,
  BankInfoTitle,
  BankInfoOptional,
  BankInfoDescription,
} from './BankInfo.css'

const BankInfo = ({
  currentStep,
  handleNextStep,
}) => {
  const dispatch = useDispatch()
  const t = useTranslate()
  const [ref, , height] = useResizeObserver()
  const [isCompleted, setCompleted] = useState(false)
  const isActive = currentStep === 1

  const receiveMessage = event => {
    if (event.data.type === 'iframe_success') {
      setCompleted(true)
    }
  }

  useEffect(() => {
    window.addEventListener('message', receiveMessage)
    return () => window.removeEventListener('message', receiveMessage)
  }, [])

  useEffect(() => {
    if (isActive) {
      dispatch(setContainerHeight(height))
    }
  }, [currentStep, height])

  return (
    <>
      {isActive && (
        <Helmet>
          <title>{t('application.bankInfo.head.title')}</title>
          <meta name="description" content={t('application.bankInfo.head.description')} />
        </Helmet>
      )}
      <BankInfoContainer data-cy="application-flow-step-3" ref={ref}>
        <BankInfoTitle>{ t('application.bankInfo.title') }</BankInfoTitle>
        <BankInfoOptional>{ t('application.optionalText') }</BankInfoOptional>
        <BankInfoDescription>{ t('application.bankInfo.description') }</BankInfoDescription>
        <ConnectBankRecords />
        <Button
          disabled={!isCompleted}
          fullwidth
          onClick={handleNextStep}
          secondary
        >
          {t('application.bankInfo.button.next')}
        </Button>
        {!isCompleted && (
          <Button
            fullwidth
            onClick={handleNextStep}
            style={{ marginTop: '16px' }}
            tertiary
            data-cy="bank-info-button-skip"
          >
            {t('application.bankInfo.button.skip')}
          </Button>
        )}
      </BankInfoContainer>
    </>
  )
}

BankInfo.propTypes = {
  currentStep: PropTypes.number.isRequired,
  handleNextStep: PropTypes.func.isRequired,
}

export default connect(
  state => ({
    currentStep: state.application.currentStep,
  }),
)(BankInfo)
