const parseOrganizationNumber = str => {
  const ORGANIZATION_NUMBER_REGEXP = /^([0-9]{6})-?([0-9]{4})$/

  if (typeof str !== 'string') {
    throw new Error('Invalid organization number: bad input')
  }

  if (!ORGANIZATION_NUMBER_REGEXP.test(str)) {
    throw new Error('Invalid organization number: bad format')
  }

  return str.replace(/(-|\+)/g, '')
}

export default parseOrganizationNumber
