import { css } from 'styled-components'
import Breakpoints from 'constants/breakpoints'

// This creates the media templates, which allows for simple
// breakpoint usage inside styled-components, e.g.:
//
// ${mq.phone`
//   font-size: 1.6rem;
// `};
//
// ${mq.max_tablet`
//   display: flex;
// `};
//
// Edit or add breakpoints inside constants/breakpoints.js

const mq = Object.keys(Breakpoints).reduce((acc, label) => {
  acc[`max_${label}`] = (...args) => css`
    @media (max-width: ${Breakpoints[label] / 16}em) {
      ${css(...args)};
    }
  `

  acc[label] = (...args) => css`
    @media (min-width: ${Breakpoints[label] / 16}em) {
      ${css(...args)};
    }
  `

  return acc
}, {})

export default mq
