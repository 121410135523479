import { useEffect, useState } from 'react'

const targets = []

const getTarget = name => targets.find(t => t.name === name)

const easeInOutQuad = t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)

const useScrollTo = () => {
  const [state, setState] = useState([])

  const register = (name, ref) => {
    if (!getTarget(name)) {
      targets.push({ name, ref })
    }
  }

  const scrollTo = (nameOrRef, duration = 0.5) => {
    let target
    if (typeof nameOrRef !== 'undefined' && nameOrRef.current) {
      target = { ref: nameOrRef }
    } else {
      target = getTarget(nameOrRef)
    }

    if (!target) {
      return false
    }

    const scrollTop = window.pageYOffset
    const rect = target.ref.current.getBoundingClientRect()
    const navbarHeight = 88

    setState([
      scrollTop,
      scrollTop + rect.top - navbarHeight,
      duration,
      performance.now(),
    ])

    return true
  }

  const animate = now => {
    const [start, end, duration, startTime] = state
    const elapsed = (now - startTime) * 0.001

    const l = Math.min(elapsed / duration, 1)
    window.scrollTo(0, start + (end - start) * easeInOutQuad(l))

    if (l < 1) {
      window.requestAnimationFrame(animate)
    }
  }

  useEffect(() => {
    if (state.length) {
      window.requestAnimationFrame(animate)
    }
  }, [state])

  return {
    register,
    scrollTo,
  }
}

export default useScrollTo
