import { put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  AUTH_FAILURE,
  AUTH_LOGOUT,
  AUTH_PENDING,
  AUTH_SUCCESS,
} from 'store/constants/auth'
import { logoutUser } from '../actions/authActions'

const clearStore = () => {
  logoutUser()
}

export function logout() {
  try {
    clearStore()
  } catch (err) {
    clearStore()
  }
}

function* authWatcher(action) {
  switch (action.type) {
    case AUTH_PENDING:
    default:
      yield put({ type: AUTH_SUCCESS })
      yield put(push(action.redirectTo))
  }
}

function* authSaga() {
  yield takeEvery([
    AUTH_FAILURE,
    AUTH_LOGOUT,
    AUTH_PENDING,
    AUTH_SUCCESS,
  ], authWatcher)
}

export default authSaga
