export const APPLICATION_SET_COMPANY_INFO = 'qred/APPLICATION_SET_COMPANY_INFO'
export const APPLICATION_SET_NAME = 'qred/APPLICATION_SET_NAME'
export const APPLICATION_SET_ID = 'qred/APPLICATION_SET_ID'
export const APPLICATION_SET_INFO = 'qred/APPLICATION_SET_INFO'
export const APPLICATION_SET_LOAN_DETAILS = 'qred/APPLICATION_SET_LOAN_DETAILS'
export const APPLICATION_SET_STATUS = 'qred/APPLICATION_SET_STATUS'
export const APPLICATION_SET_STEP = 'qred/APPLICATION_SET_STEP'

export const APPLICATION_COMPANY_ENGAGEMENTS_LOADED = 'qred/APPLICATION_COMPANY_ENGAGEMENTS_LOADED'
export const APPLICATION_FETCH_COMPANY_ENGAGEMENTS = 'qred/APPLICATION_FETCH_COMPANY_ENGAGEMENTS'
export const APPLICATION_SET_COMPANY_ENGAGEMENTS = 'qred/APPLICATION_SET_COMPANY_ENGAGEMENTS'

export const APPLICATION_APPLICATION_LOADED = 'qred/APPLICATION_APPLICATION_LOADED'
export const APPLICATION_FETCH_APPLICATION = 'qred/APPLICATION_FETCH_APPLICATION'
export const APPLICATION_FETCHED = 'qred/APPLICATION_FETCHED'
export const APPLICATION_INIT_APPLICATION = 'qred/APPLICATION_INIT_APPLICATION'
export const APPLICATION_STOP_POLLING = 'qred/APPLICATION_STOP_POLLING'

export const APPLICATION_FINALIZE_APPLICATION = 'qred/APPLICATION_FINALIZE_APPLICATION'
export const APPLICATION_FINALIZE_FAILED = 'qred/APPLICATION_FINALIZE_FAILED'
export const APPLICATION_FINALIZE_SUCCESS = 'qred/APPLICATION_FINALIZE_SUCCESS'

export const APPLICATION_IS_UPDATING = 'qred/APPLICATION_IS_UPDATING'
export const APPLICATION_UPDATE_APPLICATION = 'qred/APPLICATION_UPDATE_APPLICATION'
export const APPLICATION_UPDATE_FAILED = 'qred/APPLICATION_UPDATE_FAILED'
export const APPLICATION_UPDATE_SUCCESS = 'qred/APPLICATION_UPDATE_SUCCESS'
export const APPLICATION_SET_RLC = 'qred/APPLICATION_SET_RLC'
export const APPLICATION_UPLOAD_DOCUMENTS = 'qred/APPLICATION_UPLOAD_DOCUMENTS'

export const APPLICATION_STEP_ONE_COMPLETED = 'qred/APPLICATION_STEP_ONE_COMPLETED'
export const APPLICATION_HANDLE_NEXT_STEP = 'qred/APPLICATION_HANDLE_NEXT_STEP'
export const APPLICATION_NAVIGATE = 'qred/APPLICATION_NAVIGATE'
