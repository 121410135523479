import styled from 'styled-components'
import mq from 'helpers/mq'

const NavigationContainer = styled.div`
  display: none;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
`

const MobileMenu = styled.div`
  display: none;
  ${mq.desktop`
    display: none;
  `};
`

const MobileMenuItem = styled.div`
  cursor: pointer;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
`

const DesktopMenu = styled.div`
  display: none;

  ${mq.desktop`
    display: flex;
  `};
`

const DesktopMenuItem = styled.div`
  display: none;
  font-size: 13px;
  margin: 0 10px;
`

const DrawerMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const DrawerMenuItem = styled.a`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export {
  NavigationContainer,
  MobileMenu,
  MobileMenuItem,
  DesktopMenu,
  DesktopMenuItem,
  DrawerMenu,
  DrawerMenuItem,
}
