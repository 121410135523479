export const QRED_LOAN_PURPOSES = {
  BUY_EQUIPMENT: 'BUY_EQUIPMENT',
  INVENTORY: 'INVENTORY',
  REPAY_OTHER_LOAN: 'REPAY_OTHER_LOAN',
  EMPLOY_PERSONNEL: 'EMPLOY_PERSONNEL',
  MARKETING: 'MARKETING',
  EXPANSION: 'EXPANSION',
  WORKING_CAPITAL: 'WORKING_CAPITAL',
  OTHER: 'OTHER',
}

export const QRED_PRIORITY_BANKS = {
  se: [
    'SE_DanskeBankForetag',
    'SE_HandelsbankenForetag',
    'SE_NordeaForetag',
    'SE_SebForetag',
    'SE_SparbankernaForetag',
    'SE_SwedbankForetag',
  ],
}

export const QRED_PAGE_TO_SEQUENCE = {
  se: [
    'ditt-lan',
    'finansiell-info',
    'foretags-info',
    'tack',
  ],
  'en-se': [
    'your-loan',
    'financial-info',
    'company-info',
    'thank-you',
  ],
}

export default {
  QRED_LOAN_PURPOSES,
  QRED_PAGE_TO_SEQUENCE,
  QRED_PRIORITY_BANKS,
}
