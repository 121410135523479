import initialState from 'store/initialState'
import {
  APPLICATION_APPLICATION_LOADED,
  APPLICATION_COMPANY_ENGAGEMENTS_LOADED,
  APPLICATION_SET_NAME,
  APPLICATION_SET_COMPANY_ENGAGEMENTS,
  APPLICATION_SET_COMPANY_INFO,
  APPLICATION_SET_ID,
  APPLICATION_SET_LOAN_DETAILS,
  APPLICATION_SET_STATUS,
  APPLICATION_SET_STEP,
  APPLICATION_STEP_ONE_COMPLETED,
  APPLICATION_UPDATE_APPLICATION,
  APPLICATION_UPDATE_FAILED,
  APPLICATION_UPDATE_SUCCESS,
  APPLICATION_SET_RLC,
  APPLICATION_FINALIZE_APPLICATION,
  APPLICATION_UPLOAD_DOCUMENTS,
} from '../constants/application'

const applicationReducer = (state = initialState.application, action) => {
  const { type, ...payload } = action

  switch (type) {
    case APPLICATION_SET_ID:
      return {
        ...state,
        applicationId: payload.applicationId,
      }
    case APPLICATION_SET_STATUS:
      return {
        ...state,
        applicationStatus: payload.status,
      }
    case APPLICATION_APPLICATION_LOADED:
      return {
        ...state,
        hasNationalIdOnLoad: payload.hasNationalIdOnLoad,
        hasOrganizationOnLoad: payload.hasOrganizationOnLoad,
      }
    case APPLICATION_SET_NAME:
      return {
        ...state,
        name: {
          first: payload.firstName,
          last: payload.lastName,
        },
      }
    case APPLICATION_SET_LOAN_DETAILS:
      return {
        ...state,
        loanDetails: {
          ...state.loanDetails,
          ...payload,
        },
      }
    case APPLICATION_SET_COMPANY_INFO:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          ...payload,
        },
      }
    case APPLICATION_SET_STEP:
      return {
        ...state,
        currentStep: payload.step,
      }
    case APPLICATION_SET_COMPANY_ENGAGEMENTS:
      return {
        ...state,
        companyList: payload.list,
        hasLoadedCompanyEngagements: true,
      }
    case APPLICATION_COMPANY_ENGAGEMENTS_LOADED:
      return {
        ...state,
        hasLoadedCompanyEngagements: true,
      }
    case APPLICATION_STEP_ONE_COMPLETED:
      return {
        ...state,
        stepOneCompleted: true,
      }
    case APPLICATION_FINALIZE_APPLICATION:
    case APPLICATION_UPLOAD_DOCUMENTS:
    case APPLICATION_UPDATE_APPLICATION:
      return {
        ...state,
        isUpdating: true,
      }
    case APPLICATION_SET_RLC:
      return {
        ...state,
        isRLC: payload.isRLC,
      }
    case APPLICATION_UPDATE_FAILED:
    case APPLICATION_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      }
    default:
      return state
  }
}

export default applicationReducer
