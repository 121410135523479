import styled, { css } from 'styled-components'
import mq from 'helpers/mq'

const ConnectBankRecordsContainer = styled.div`
  width: 100%;
`

const ConnectBankRecordsChooseBank = styled.div`

`

const ConnectBankRecordsSearchInput = styled.div`
  margin-bottom: 28px;
  margin-top: 14px;
`

const ConnectBankRecordsProviderContainer = styled.div`
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  height: 330px;
  overflow: scroll;
  padding: 12px;

  div {
    &:last-child {
      margin-bottom: 12px;
    }
  }

  ${mq.tablet`
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  `};
`

const ConnectBankRecordsZeroState = styled.div`

`

const ConnectBankRecordsIframeContainer = styled.div`
  height: 550px;
  width: 100%;

  ${props => props.size && css`
    height: ${props.size}px;
  `}
`

const ConnectBankRecordsIframe = styled.iframe`
  height: 100%;
  width: 100%;
`

export {
  ConnectBankRecordsContainer,
  ConnectBankRecordsChooseBank,
  ConnectBankRecordsSearchInput,
  ConnectBankRecordsProviderContainer,
  ConnectBankRecordsZeroState,
  ConnectBankRecordsIframeContainer,
  ConnectBankRecordsIframe,
}
