import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'

const useTranslate = () => {
  const { formatMessage } = useIntl()

  return (id, values = {}) => {
    const message = formatMessage({ id }, values)
    return Array.isArray(message) ? message.map((tag, i) => (
      <Fragment key={`${id}-${i}`}>{tag}</Fragment> // eslint-disable-line react/no-array-index-key
    )) : message
  }
}

export default useTranslate
