import { call, put, race, select, take, takeEvery } from "redux-saga/effects";
import axios from "axios";
import personnummer from "personnummer";
import { matchRoutes } from "react-router-config";
import { push, LOCATION_CHANGE } from "connected-react-router";
import parseOrganizationNumber from "helpers/parsers/parseOrganizationNumber";
import { QRED_LOAN_PURPOSES, QRED_PAGE_TO_SEQUENCE } from "helpers/qred";
import routes from "routes";
import {
  APPLICATION_COMPANY_ENGAGEMENTS_LOADED,
  APPLICATION_FETCH_APPLICATION,
  APPLICATION_FETCH_COMPANY_ENGAGEMENTS,
  APPLICATION_FETCHED,
  APPLICATION_FINALIZE_APPLICATION,
  APPLICATION_HANDLE_NEXT_STEP,
  APPLICATION_INIT_APPLICATION,
  APPLICATION_NAVIGATE,
  APPLICATION_STEP_ONE_COMPLETED,
  APPLICATION_STOP_POLLING,
  APPLICATION_UPDATE_APPLICATION,
  APPLICATION_UPLOAD_DOCUMENTS,
  APPLICATION_UPDATE_SUCCESS,
  APPLICATION_UPDATE_FAILED,
} from "../constants/application";
import {
  applicationLoaded,
  applicationUpdateFailed,
  applicationUpdateSuccess,
  fetchApplication,
  fetchCompanyEngagements,
  finalizeApplication,
  setApplicationId,
  setApplicationStatus,
  setCompanyEngagements,
  setCompanyInfo,
  setLoanDetails,
  setCurrentStep,
  updateApplication,
  uploadDocuments,
  navigateToStep,
  applicationSetRLC,
} from "../actions/application";
import { logError, initApp, setModal } from "../actions/ui";

// Gets route parameters
const getParams = (state) => {
  const match = matchRoutes(routes, state.router.location.pathname).shift();
  if (!match) {
    return {};
  }
  return match.match.params;
};

// Gets current application ID
const getApplicationId = (state) => state.application.applicationId;
// Converts step number to page name
const getPageFromStep = (step) => (state) =>
  QRED_PAGE_TO_SEQUENCE[state.intl.locale][step];

// Converts page name to step number
const getStepFromPage = (page) => (state) =>
  QRED_PAGE_TO_SEQUENCE[state.intl.locale].indexOf(page);

// Determines if first step is completed by checking:
// 1. National identification number
// 2. National organization number
// 3. Loan purpose
const isFirstStepCompleted = (state) => {
  const {
    nationalIdNumber,
    organizationNumberManual,
    purpose,
    purposeManual,
  } = state.application.loanDetails;

  if (!organizationNumberManual) {
    return false;
  }

  if (!purpose) {
    return false;
  }

  if (purpose === QRED_LOAN_PURPOSES.OTHER && !purposeManual) {
    return false;
  }

  if (!nationalIdNumber) {
    return false;
  }

  return true;
};

// Takes a file and returns the file content as a base64 encoded string
const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(window.btoa(reader.result));
    };
    reader.readAsBinaryString(blob);
  });

// Maps documents to API schema
const mapDocuments = (documents) => {
  const result = Promise.all(
    documents.map(async (doc) => ({
      fileName: doc.name,
      content: await convertBlobToBase64(doc),
    }))
  );
  return result;
};

// Waits for X milliseconds and then resolves a promise. Can be used to delay actions
const wait = (duration) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(true), duration);
  });

// Polls backoffice for application status changes
function* checkApplicationStatus() {
  const applicationId = yield select(getApplicationId);

  const resp = yield call(axios, {
    method: "post",
    url: `https://qredab.eu.auth0.com/oauth/token`,
    headers: { "content-type": "application/json" },
    data: {
      client_id: "i6QPexgVOiapuuiRDSjvBm6bAc8EO3l3",
      client_secret:
          "FPsi9ehZl7cnGSjTvWR1DRAgKhr6LE-2SORtQzU0bUIAkX7Qh7Zck5Lw_ZJdNprG",
      audience: "backOffice.qred.com",
      grant_type: "client_credentials",
    },
  });

  const token = resp.data.access_token;

  while (true) {
    try {
      const res = yield call(
        axios.get,
        `https://test.qred.com/webapi/applications/v2/applications/${applicationId}/status`,
          {
            withCredentials: false,
            headers: {
              authorization: `Bearer ${token}`,
              "content-type": "application/json"
            },
          }
      );
      const { status } = res.data;

      if (status !== "ApplicationChange") {
        yield put(setModal("application_finished"));
        yield put({ type: APPLICATION_STOP_POLLING });
      }
    } catch (err) {
      //
    }
    yield call(wait, 30000);
  }
}

function* applicationsWatcher(action) {
  const { type, ...payload } = action;

  try {
    switch (type) {
      case APPLICATION_INIT_APPLICATION: {
        const currentStep = yield select(
          (state) => state.application.currentStep
        );
        const { applicationId } = yield select(getParams);
        let firstStepCompleted = false;

        yield put(setApplicationId(applicationId));

        // Make sure we don't load a non-existing step
        if (currentStep < 0 || currentStep > 2) {
          yield put(navigateToStep(0));
        }

        // Fetch application
        yield put(fetchApplication());

        // Wait for application to load
        yield take(APPLICATION_FETCHED);
        // Get current state
        const appState = yield select((state) => state.application);

        if (appState.applicationStatus === "ApplicationChange") {
          let hasNationalIdOnLoad = window.sessionStorage.getItem(
            "has_national_id_on_load"
          );
          let hasOrganizationOnLoad = window.sessionStorage.getItem(
            "has_organization_on_load"
          );

          if (
            typeof hasNationalIdOnLoad !== "undefined" &&
            hasNationalIdOnLoad !== null
          ) {
            hasNationalIdOnLoad = JSON.parse(hasNationalIdOnLoad);
          } else {
            hasNationalIdOnLoad = !!appState.loanDetails.nationalIdNumber;
            window.sessionStorage.setItem(
              "has_national_id_on_load",
              hasNationalIdOnLoad
            );
          }

          if (
            typeof hasOrganizationOnLoad !== "undefined" &&
            hasOrganizationOnLoad !== null
          ) {
            hasOrganizationOnLoad = JSON.parse(hasOrganizationOnLoad);
          } else {
            hasOrganizationOnLoad = !!appState.loanDetails
              .organizationNumberManual;
            window.sessionStorage.setItem(
              "has_organization_on_load",
              hasOrganizationOnLoad
            );
          }

          // Only load company engagements if we don't already have
          // an organization number.
          if (hasNationalIdOnLoad) {
            yield put(fetchCompanyEngagements());

            // Wait for company engagements to load
            yield take(APPLICATION_COMPANY_ENGAGEMENTS_LOADED);
          }

          // Mark step one completed if organization number is already set
          firstStepCompleted = yield select(isFirstStepCompleted);
          if (firstStepCompleted) {
            yield put({ type: APPLICATION_STEP_ONE_COMPLETED });
            firstStepCompleted = true;
          }

          yield put(
            applicationLoaded({ hasNationalIdOnLoad, hasOrganizationOnLoad })
          );

          // Prevent user from skipping the first step
          if (currentStep > 0 && !firstStepCompleted) {
            yield put(navigateToStep(0));
          }
        } else if (window.sessionStorage.getItem("application_completed")) {
          const { locale } = yield select(getParams);

          // Get translated URL slug
          const thankYouSlug = {
            se: "tack",
            "en-se": "thank-you",
          }[locale];

          // Redirect to "thank you" page
          yield put(push(`/${locale}/applications/${thankYouSlug}`));
        } else {
          yield put(setModal("application_finished"));
        }

        // Show application view
        yield put(initApp());

        break;
      }

      case APPLICATION_FETCH_APPLICATION: {
        const applicationId = yield select(getApplicationId);
        try {
          const resp = yield call(axios, {
            method: "post",
            url: `https://qredab.eu.auth0.com/oauth/token`,
            headers: { "content-type": "application/json" },
            data: {
              client_id: "i6QPexgVOiapuuiRDSjvBm6bAc8EO3l3",
              client_secret:
                "FPsi9ehZl7cnGSjTvWR1DRAgKhr6LE-2SORtQzU0bUIAkX7Qh7Zck5Lw_ZJdNprG",
              audience: "backOffice.qred.com",
              grant_type: "client_credentials",
            },
          });

          const token = resp.data.access_token;

          const res = yield call(axios, {
            method: "get",
            url: `https://test.qred.com/webapi/applications/v2/applications/${applicationId}`,
            withCredentials: false,
            headers: {
              authorization: `Bearer ${token}`,
            },
          });

          const {
            additionalNotes,
            applicant,
            organization,
            purposeOfLoan,
            purposeOfLoanManual,
            status,
            isRLC,
          } = res.data;

          // Update application status
          yield put(setApplicationStatus(status));
          yield put(applicationSetRLC(isRLC));

          // Update company info view
          yield put(
            setCompanyInfo({
              website: organization.website,
              message: additionalNotes,
            })
          );

          // Update loan details view
          yield put(
            setLoanDetails({
              nationalIdNumber: applicant.nationalIdentificationNumber,
              organizationNumber: {
                value: organization.nationalOrganizationNumber,
              },
              organizationNumberManual: organization.nationalOrganizationNumber,
              purpose: {
                value: purposeOfLoan,
              },
              purposeManual: purposeOfLoanManual,
            })
          );
        } catch (err) {
          // TODO: Handle API errors
          yield put(setModal("application_fatal_error"));
          throw err;
        }

        yield put({ type: APPLICATION_FETCHED });
        break;
      }

      case APPLICATION_UPDATE_APPLICATION: {
        const applicationId = yield select(getApplicationId);
        const appState = yield select((state) => state.application);

        try {
          // Submit manually typed org. number if present otherwise default to list selection
          const orgNumber =
            appState.loanDetails.organizationNumberManual ||
            appState.loanDetails.organizationNumber.value;

          const data = {
            nationalIdentificationNumber: personnummer.format(
              appState.loanDetails.nationalIdNumber,
              true
            ),
            nationalOrganizationNumber: parseOrganizationNumber(orgNumber),
            purposeOfLoan: appState.loanDetails.purpose.value,
            purposeOfLoanManual: appState.loanDetails.purposeManual,
            website: appState.companyInfo.website,
            additionalNotes: appState.companyInfo.message,
          };

          // Remove undefined properties from payload
          Object.keys(data).forEach(
            (key) => data[key] === undefined && delete data[key]
          );

          const resp = yield call(axios, {
            method: "post",
            url: `https://qredab.eu.auth0.com/oauth/token`,
            headers: { "content-type": "application/json" },
            data: {
              client_id: "i6QPexgVOiapuuiRDSjvBm6bAc8EO3l3",
              client_secret:
                "FPsi9ehZl7cnGSjTvWR1DRAgKhr6LE-2SORtQzU0bUIAkX7Qh7Zck5Lw_ZJdNprG",
              audience: "backOffice.qred.com",
              grant_type: "client_credentials",
            },
          });

          const token = resp.data.access_token;

          const rest = yield call(
            axios.post,
            `https://test.qred.com/webapi/applications/v2/applications/${applicationId}`,
            data,
            {
              withCredentials: false,
              headers: {
                authorization: `Bearer ${token}`,
                "content-type": "application/json"
              },
            }
          );

          const { isRLC } = rest.data;
          yield put(applicationSetRLC(isRLC));

          if (!appState.stepOneCompleted) {
            yield put({ type: APPLICATION_STEP_ONE_COMPLETED });
          }

          yield put(applicationUpdateSuccess());
        } catch (err) {
          //
          yield put(setModal("application_error"));
          yield put(applicationUpdateFailed());
        }
        break;
      }
      case APPLICATION_FINALIZE_APPLICATION: {
        const applicationId = yield select(getApplicationId);

        try {
          // Lock application from further changes and transition it to the next state
          // yield call(
          //   axios.post,
          //   `https://test.qred.com/webapi/applications/v2/applications/${applicationId}/finalize`
          // );
          // yield call(
          //   axios.post,
          //   `https://test.qred.com/webapi/applications/v2/applications/${applicationId}/proceed`
          // );
          const resp = yield call(axios, {
            method: "post",
            url: `https://qredab.eu.auth0.com/oauth/token`,
            headers: { "content-type": "application/json" },
            data: {
              client_id: "i6QPexgVOiapuuiRDSjvBm6bAc8EO3l3",
              client_secret:
                "FPsi9ehZl7cnGSjTvWR1DRAgKhr6LE-2SORtQzU0bUIAkX7Qh7Zck5Lw_ZJdNprG",
              audience: "backOffice.qred.com",
              grant_type: "client_credentials",
            },
          });
          const token = resp.data.access_token;
          const res = yield call(axios, {
            method: "get",
            url: `https://test.qred.com/webapi/applications/v2/applications/${applicationId}/proceed`,
            withCredentials: false,
            headers: {
              authorization: `Bearer ${token}`,
            },
          });

          // Remember that the application was completed so we can redirect back to the
          // thank you page if the customer clicks on the back button.
          window.sessionStorage.setItem("application_completed", true);

          // Stop status check
          yield put({ type: APPLICATION_STOP_POLLING });

          yield put(applicationUpdateSuccess());
        } catch (err) {
          yield put(applicationUpdateFailed());
        }
        break;
      }
      case APPLICATION_FETCH_COMPANY_ENGAGEMENTS: {
        const appState = yield select((state) => state.application);
        try {
          const res = yield call(
            axios.get,
            `${process.env.API_GATEWAY}/lookup/person`,
            {
              params: {
                personalNumber: appState.loanDetails.nationalIdNumber,
              },
            }
          );

          yield put(setCompanyEngagements(res.data));

          // Preselect single organization
          if (res.data.length === 1) {
            yield put(
              setLoanDetails({
                organizationNumber: {
                  value: res.data[0].orgNumber,
                },
              })
            );
          }
        } catch (err) {
          //
        }

        yield put({ type: APPLICATION_COMPANY_ENGAGEMENTS_LOADED });

        break;
      }
      case APPLICATION_UPLOAD_DOCUMENTS: {
        const applicationId = yield select(getApplicationId);
        const appState = yield select((state) => state.application);
        const resp = yield call(axios, {
          method: "post",
          url: `https://qredab.eu.auth0.com/oauth/token`,
          headers: { "content-type": "application/json" },
          data: {
            client_id: "i6QPexgVOiapuuiRDSjvBm6bAc8EO3l3",
            client_secret:
                "FPsi9ehZl7cnGSjTvWR1DRAgKhr6LE-2SORtQzU0bUIAkX7Qh7Zck5Lw_ZJdNprG",
            audience: "backOffice.qred.com",
            grant_type: "client_credentials",
          },
        });

        const token = resp.data.access_token;

        try {
          const data = yield call(mapDocuments, appState.companyInfo.documents);
          yield call(
            axios.post,
            `https://test.qred.com/webapi/applications/v2/applications/${applicationId}/files`,
            data,
              {
                withCredentials: false,
                headers: {
                  authorization: `Bearer ${token}`,
                  "content-type": "application/json"
                },
              }
          );

          yield put(applicationUpdateSuccess());
        } catch (err) {
          yield put(applicationUpdateFailed());
        }
        break;
      }
      case APPLICATION_HANDLE_NEXT_STEP: {
        // TODO: Refactor error handling

        const appState = yield select((state) => state.application);
        const params = yield select(getParams);
        const { locale } = params;

        yield put(updateApplication());

        // Send application data to backoffice
        const [, updateFailed] = yield race([
          take(APPLICATION_UPDATE_SUCCESS),
          take(APPLICATION_UPDATE_FAILED),
        ]);

        if (updateFailed) {
          yield put(setModal("application_error"));
          return;
        }

        if (appState.currentStep < 2) {
          yield put(navigateToStep(appState.currentStep + 1));
        } else {
          // Upload any pending documents
          if (appState.companyInfo.documents.length) {
            yield put(uploadDocuments());

            const [, uploadFailed] = yield race([
              take(APPLICATION_UPDATE_SUCCESS),
              take(APPLICATION_UPDATE_FAILED),
            ]);

            if (uploadFailed) {
              yield put(setModal("application_error"));
              return;
            }
          }

          yield put(finalizeApplication());

          const [, finalizeFailed] = yield race([
            take(APPLICATION_UPDATE_SUCCESS),
            take(APPLICATION_UPDATE_FAILED),
          ]);

          if (finalizeFailed) {
            yield put(setModal("application_error"));
            return;
          }

          // Get translated URL slug
          const thankYouSlug = {
            se: "tack",
            "en-se": "thank-you",
          }[locale];

          // Redirect to "thank you" page
          yield put(push(`/${locale}/applications/${thankYouSlug}`));
        }

        // Reset scroll position
        window.scrollTo(0, 0);
        break;
      }
      case APPLICATION_STEP_ONE_COMPLETED: {
        yield race([
          call(checkApplicationStatus),
          take(APPLICATION_STOP_POLLING),
        ]);
        break;
      }
      case APPLICATION_NAVIGATE: {
        const { locale, applicationId } = yield select(getParams);
        const nextStep = yield select(getPageFromStep(payload.step));
        yield put(push(`/${locale}/applications/${applicationId}/${nextStep}`));
        break;
      }
      case LOCATION_CHANGE: {
        const { pageName } = yield select(getParams);
        const currentStep = yield select(getStepFromPage(pageName));

        yield put(setCurrentStep(currentStep));
        break;
      }
      default:
    }
  } catch (err) {
    yield put(logError(err));
  }
}

function* applicationSaga() {
  yield takeEvery(
    [
      APPLICATION_FETCH_APPLICATION,
      APPLICATION_FETCH_COMPANY_ENGAGEMENTS,
      APPLICATION_FINALIZE_APPLICATION,
      APPLICATION_HANDLE_NEXT_STEP,
      APPLICATION_INIT_APPLICATION,
      APPLICATION_NAVIGATE,
      APPLICATION_STEP_ONE_COMPLETED,
      APPLICATION_UPDATE_APPLICATION,
      APPLICATION_UPLOAD_DOCUMENTS,
      LOCATION_CHANGE,
    ],
    applicationsWatcher
  );
}

export default applicationSaga;
