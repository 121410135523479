import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useTranslate from 'hooks/useTranslate'
import {
  themes,
  ThemeProvider,
} from '@qred/ui-components'
import { Helmet } from 'react-helmet-async'
import Navigation from 'components/Navigation'
import {
  NotFoundContainer,
  NotFoundContent,
  NotFoundTitle,
  NotFoundSubTitle,
  NotFoundBackLink,
} from './NotFound.css'

const NotFound = () => {
  const t = useTranslate()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  return (
    <ThemeProvider theme={themes.swagnes}>
      <NotFoundContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ t('notFound.head.title') }</title>
          <meta name="description" content={t('notFound.head.description')} />
          <link rel="canonical" href="http://app.qred.ninja/" />
          <link rel="icon" type="image/png" href="/favicon.png" />
        </Helmet>
        <Navigation
          toggleDrawer={toggleDrawer}
          drawerOpen={drawerOpen}
        />
        <NotFoundContent>
          <NotFoundTitle>{ t('notFound.title') }</NotFoundTitle>
          <NotFoundSubTitle>{ t('notFound.subtitle') }</NotFoundSubTitle>
          <NotFoundBackLink href={t('links.home.url')}>{ t('notFound.backText') }</NotFoundBackLink>
        </NotFoundContent>
      </NotFoundContainer>
    </ThemeProvider>
  )
}

export default NotFound
