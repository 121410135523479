import React, { useEffect, useRef, useState } from 'react'
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  fetchBankAccountHistory,
} from 'store/actions/bankAccountsActions'
import {
  ThemeProvider,
  themes,
} from '@qred/ui-components'
import ResponseMessage from './components/ResponseMessage'
import {
  BankAccountResponseContainer,
} from './BankAccountResponse.css'

const BankAccountResponse = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const containerRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [code, setCode] = useState('')
  const [success, setSuccess] = useState(false)
  const [userId, setUserId] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    const dispatchFetchBankAccountHistory = async () => {
      try {
        await dispatch(fetchBankAccountHistory({
          code,
          userId,
        }))

        window.parent.postMessage({ type: 'iframe_success' }, '*')
      } catch (e) {
        console.log('Fail?')
        setSuccess(false)
      }

      setLoading(false)
    }
    const query = qs.parse(location.search.substring(1))

    setCode(query.code)
    setSuccess(query.success)
    setUserId(query.userid)
    setError(query.error)

    if (window.parent) {
      window.parent.postMessage({ type: 'iframe_resize', height: containerRef.current.scrollHeight }, '*')
    }

    if (code) {
      dispatchFetchBankAccountHistory()
    } else {
      setLoading(false)
    }

    return () => {}
  }, [code])

  const handleTryAgainClick = () => {
    if (window.parent) {
      window.parent.postMessage({ type: 'iframe_reload' }, '*')
    }
  }

  let jsxResponse = (
    <ResponseMessage
      type="error"
      onClick={handleTryAgainClick}
    />
  )

  if (loading) {
    jsxResponse = (
      <ResponseMessage
        type="loading"
      />
    )
  } else if (success === 'false' || success === false) {
    // error: Cancel, when user cancels the iframe session by clicking a cancel action
    if (error === 'Cancel') {
      handleTryAgainClick()
    } else {
      jsxResponse = (
        <ResponseMessage
          type="error"
          onClick={handleTryAgainClick}
        />
      )
    }
  } else if (code && userId) {
    jsxResponse = (
      <ResponseMessage
        type="success"
      />
    )
  }

  return (
    <ThemeProvider theme={themes.swagnes}>
      <BankAccountResponseContainer ref={containerRef}>
        {jsxResponse}
      </BankAccountResponseContainer>
    </ThemeProvider>
  )
}

export default BankAccountResponse
