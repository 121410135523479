import React from 'react'
import PropTypes from 'prop-types'

import {
  AppContainerElement,
  AppContainerSnake,
} from './AppContainerElement.css'

const AppContainer = ({ children }) => (
  <>
    <AppContainerSnake/>
    <AppContainerElement>
      {children}
    </AppContainerElement>
  </>
)

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppContainer
