import styled from 'styled-components'

const BankInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const BankInfoTitle = styled.h3`
  margin-bottom: 6px;
`

const BankInfoOptional = styled.small`
  color: #808080;
  font-weight: 300;
  margin-bottom: 8px;
`

const BankInfoDescription = styled.p`
  margin-bottom: 32px;
`

export {
  BankInfoContainer,
  BankInfoTitle,
  BankInfoOptional,
  BankInfoDescription,
}
