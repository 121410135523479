import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect, useDispatch } from "react-redux";
import useResizeObserver from "use-resize-observer";
import { Helmet } from "react-helmet-async";

import { QRED_LOAN_PURPOSES } from "helpers/qred";
import useTranslate from "hooks/useTranslate";
import useScrollTo from "hooks/useScrollTo";
import { setLoanDetails } from "store/actions/application";
import { setContainerHeight } from "store/actions/ui";

import {
  Icon,
  LoadingButton,
  MultipleChoice,
  TextInput,
} from "@qred/ui-components";

import {
  LoanDetailsContainer,
  LoanDetailsError,
  LoanDetailsGreeting,
  LoanDetailsNationalIdSection,
  LoanDetailsNationalIdTitle,
  LoanDetailsTitle,
  LoanDetailsCompanyLocked,
  LoanDetailsCompanySection,
  LoanDetailsCompanyTitle,
  LoanDetailsPurposeSection,
  LoanDetailsPurposeTitle,
  LoanDetailsCompanyManualTitle,
} from "./LoanDetails.css";

const PERSONAL_NUMBER_REGEXP = /^(((20)((0[0-9])|(1[0-1])))|(([1][^0-8])?\d{2}))((0[1-9])|1[0-2])((0[1-9])|(1[0-9])|(2[0-9])|(3[01]))[-]?\d{4}$/;
const ORGANIZATION_NUMBER_REGEXP = /^(19|20)?[0-9]{6}[- ]?[0-9]{4}$/;

const LoanDetails = ({
  companyList,
  currentStep,
  handleNextStep,
  hasLoadedCompanyEngagements,
  hasNationalIdOnLoad,
  hasOrganizationOnLoad,
  stepOneCompleted,
  loanDetails,
  name,
  isUpdating,
}) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const [ref, , height] = useResizeObserver();
  const [errors, setErrors] = useState({});
  const nationalIdRef = useRef(null);
  const orgNumberRef = useRef(null);
  const loanPurposeRef = useRef(null);
  const scroller = useScrollTo();
  const isActive = currentStep === 0;
  const {
    nationalIdNumber,
    organizationNumber = "",
    organizationNumberManual,
    purpose,
    purposeManual,
  } = loanDetails;

  const choices = [
    {
      label: t("application.loanDetails.purpose.BUY_EQUIPMENT"),
      value: QRED_LOAN_PURPOSES.BUY_EQUIPMENT,
      iconUrl:
        "https://static.qred.com/sv-se/bilder/icons8-shopping-bag-full-500.svg",
    },
    {
      label: t("application.loanDetails.purpose.INVENTORY"),
      value: QRED_LOAN_PURPOSES.INVENTORY,
      iconUrl: "https://static.qred.com/sv-se/bilder/icons8-trolley-500.svg",
    },
    {
      label: t("application.loanDetails.purpose.REPAY_OTHER_LOAN"),
      value: QRED_LOAN_PURPOSES.REPAY_OTHER_LOAN,
      iconUrl: "https://static.qred.com/sv-se/bilder/icons8-debt-500.svg",
    },
    {
      label: t("application.loanDetails.purpose.EMPLOY_PERSONNEL"),
      value: QRED_LOAN_PURPOSES.EMPLOY_PERSONNEL,
      iconUrl: "https://static.qred.com/sv-se/bilder/icons8-workers-500.svg",
    },
    {
      label: t("application.loanDetails.purpose.MARKETING"),
      value: QRED_LOAN_PURPOSES.MARKETING,
      iconUrl: "https://static.qred.com/sv-se/bilder/icons8-commercial-500.svg",
    },
    {
      label: t("application.loanDetails.purpose.EXPANSION"),
      value: QRED_LOAN_PURPOSES.EXPANSION,
      iconUrl: "https://static.qred.com/sv-se/bilder/icons8-rocket-500.svg",
    },
    {
      label: t("application.loanDetails.purpose.WORKING_CAPITAL"),
      value: QRED_LOAN_PURPOSES.WORKING_CAPITAL,
      iconUrl: "https://static.qred.com/sv-se/bilder/icons8-money-500.svg",
    },
    {
      label: t("application.loanDetails.purpose.OTHER"),
      value: QRED_LOAN_PURPOSES.OTHER,
      iconUrl:
        "https://static.qred.com/sv-se/bilder/icons8-question-mark-500.svg",
    },
  ];

  const companyChoices = useMemo(
    () =>
      companyList.map((c) => ({
        label: c.companyName,
        line2: c.orgNumber,
        value: c.orgNumber,
      })),
    [companyList]
  );

  const handleChangeNationalIdNumber = (e) => {
    const newErrors = errors;
    newErrors.nationalId = "";
    setErrors(newErrors);
    dispatch(
      setLoanDetails({
        nationalIdNumber: e.target.value,
      })
    );
  };

  const handleChangeCompany = (e) => {
    dispatch(
      setLoanDetails({
        organizationNumber: e,
        organizationNumberManual: "",
      })
    );
  };

  const handleChangeOrganizationNumber = (e) => {
    const newErrors = errors;
    newErrors.orgNumberManual = "";
    setErrors(newErrors);
    dispatch(
      setLoanDetails({
        organizationNumber: {
          value: null,
        },
        organizationNumberManual: e.target.value,
      })
    );
  };

  const handleChangePurpose = (newPurpose) => {
    const newErrors = errors;
    newErrors.purpose = false;
    setErrors(newErrors);
    dispatch(
      setLoanDetails({
        purpose: newPurpose,
        purposeManual:
          newPurpose === QRED_LOAN_PURPOSES.OTHER ? purposeManual : "",
      })
    );
  };

  const handleChangePurposeManual = (e) => {
    dispatch(
      setLoanDetails({
        purposeManual: e.target.value,
      })
    );
  };

  const scrollToFirstError = (newErrors) => {
    if (newErrors.nationalId) return scroller.scrollTo(nationalIdRef);
    if (newErrors.orgNumberManual) return scroller.scrollTo(orgNumberRef);
    if (newErrors.purpose) return scroller.scrollTo(loanPurposeRef);
    return false;
  };

  const handleSubmit = () => {
    const newErrors = {};
    if (nationalIdNumber === undefined || nationalIdNumber === "") {
      newErrors.nationalId = t("application.fieldErrors.personalNumberMissing");
    } else if (!PERSONAL_NUMBER_REGEXP.test(nationalIdNumber)) {
      newErrors.nationalId = t("application.fieldErrors.invalidPersonalNumber");
    }
    if (purpose.value === undefined) {
      newErrors.purpose = t("application.fieldErrors.choosePurpose");
    }
    if (
      purpose.value === QRED_LOAN_PURPOSES.OTHER &&
      (purposeManual === undefined || purposeManual.trim() === "")
    ) {
      newErrors.purpose = t("application.fieldErrors.specifypurposeReason");
    }

    if (
      !ORGANIZATION_NUMBER_REGEXP.test(organizationNumber.value) &&
      !ORGANIZATION_NUMBER_REGEXP.test(organizationNumberManual)
    ) {
      newErrors.orgNumberManual = t("application.fieldErrors.invalidOrgNumber");
    }

    setErrors(newErrors);

    if (!Object.values(newErrors).length) {
      handleNextStep();
    }
  };

  let UserName = null;
  let userName = null;

  if (name && name.first && name.first.length > 0) {
    userName = name.first;
    UserName = userName
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
    if (isActive) {
      dispatch(setContainerHeight(height));
    }
  }, [currentStep, height]);

  useEffect(() => {
    scrollToFirstError(errors);
  }, [errors]);

  return (
    <>
      {isActive && (
        <Helmet>
          <title>{t("application.loanDetails.head.title")}</title>
          <meta
            name="description"
            content={t("application.loanDetails.head.description")}
          />
        </Helmet>
      )}
      <LoanDetailsContainer data-cy="application-flow-step-2" ref={ref}>
        {UserName && (
          <LoanDetailsGreeting>{`${t(
            "application.loanDetails.greeting"
          )} ${UserName}!`}</LoanDetailsGreeting>
        )}
        <LoanDetailsTitle>
          {t("application.loanDetails.title")}
        </LoanDetailsTitle>

        {!hasNationalIdOnLoad && (
          <LoanDetailsNationalIdSection ref={nationalIdRef}>
            <LoanDetailsNationalIdTitle>
              {t("application.loanDetails.nationalIdNumber.title")}
            </LoanDetailsNationalIdTitle>
            <TextInput
              value={nationalIdNumber}
              type="tel"
              onChange={handleChangeNationalIdNumber}
              label={t("application.loanDetails.nationalIdNumber.inputLabel")}
              grey
              data-cy="loan-details-personal-number"
            />
            {errors.nationalId && (
              <LoanDetailsError>
                <Icon name="warning" />
                {errors.nationalId}
              </LoanDetailsError>
            )}
          </LoanDetailsNationalIdSection>
        )}

        {!hasOrganizationOnLoad && (
          <LoanDetailsCompanySection ref={orgNumberRef}>
            <LoanDetailsCompanyTitle disabled={stepOneCompleted}>
              {t("application.loanDetails.organizationNumber.title")}
            </LoanDetailsCompanyTitle>
            {hasLoadedCompanyEngagements && (
              <>
                {!!companyChoices.length && (
                  <>
                    <MultipleChoice
                      disabled={stepOneCompleted}
                      style={{ marginTop: "40px" }}
                      choices={companyChoices}
                      selected={organizationNumber}
                      onChange={handleChangeCompany}
                      data-cy="loan-details-company-list"
                    />
                    {errors.orgNumber && (
                      <LoanDetailsError>
                        <Icon name="warning" />
                        {errors.orgNumber}
                      </LoanDetailsError>
                    )}
                    <LoanDetailsCompanyManualTitle disabled={stepOneCompleted}>
                      {t(
                        "application.loanDetails.organizationNumberManual.title"
                      )}
                    </LoanDetailsCompanyManualTitle>
                  </>
                )}
              </>
            )}

            <TextInput
              disabled
              value={organizationNumberManual}
              onChange={handleChangeOrganizationNumber}
              label={t(
                "application.loanDetails.organizationNumberManual.inputLabel"
              )}
              type="tel"
              error={t(
                "application.loanDetails.organizationNumberManual.inputError"
              )}
            />
            {errors.orgNumberManual && (
              <LoanDetailsError>
                <Icon name="warning" />
                {errors.orgNumberManual}
              </LoanDetailsError>
            )}
            {stepOneCompleted && (
              <LoanDetailsCompanyLocked>
                {t("application.loanDetails.organizationLocked.text", {
                  br: <br />,
                  link: (
                    <a href={t("links.home.url")}>
                      {t("application.loanDetails.organizationLocked.link")}
                    </a>
                  ),
                })}
              </LoanDetailsCompanyLocked>
            )}
          </LoanDetailsCompanySection>
        )}

        <LoanDetailsPurposeSection ref={loanPurposeRef}>
          <LoanDetailsPurposeTitle>
            {t("application.loanDetails.purpose.title")}
          </LoanDetailsPurposeTitle>
          <MultipleChoice
            style={{ marginTop: "40px" }}
            icon
            choices={choices}
            selected={purpose}
            onChange={handleChangePurpose}
            error={t(
              "application.loanDetails.organizationNumberManual.inputError"
            )}
            data-cy="loan-details-purpose"
          />
          {errors.purpose && (
            <LoanDetailsError>
              <Icon name="warning" />
              {errors.purpose}
            </LoanDetailsError>
          )}
          {purpose.value === QRED_LOAN_PURPOSES.OTHER && (
            <TextInput
              value={purposeManual}
              onChange={handleChangePurposeManual}
              label={t("application.loanDetails.purposeManual.inputLabel")}
              maxLength={200}
              grey
              data-cy="loan-details-purpose-manual"
            />
          )}
        </LoanDetailsPurposeSection>

        <LoadingButton
          fullwidth
          isLoading={isUpdating}
          onClick={handleSubmit}
          secondary
          data-cy="loan-details-button-continue"
        >
          {t("application.loanDetails.button.next")}
        </LoadingButton>
      </LoanDetailsContainer>
    </>
  );
};

LoanDetails.defaultProps = {
  name: {
    first: "",
    last: "",
  },
};

LoanDetails.propTypes = {
  companyList: PropTypes.arrayOf(
    PropTypes.shape({
      companyName: PropTypes.string,
      orgNumber: PropTypes.string,
      function: PropTypes.string,
    })
  ).isRequired,
  loanDetails: PropTypes.shape({
    nationalIdNumber: PropTypes.string,
    organizationNumber: PropTypes.shape({
      value: PropTypes.string,
    }),
    organizationNumberManual: PropTypes.string,
    purpose: PropTypes.shape({
      value: PropTypes.string,
    }),
    purposeManual: PropTypes.string,
  }).isRequired,
  name: PropTypes.shape({
    first: PropTypes.string,
    last: PropTypes.string,
  }),
  handleNextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  hasLoadedCompanyEngagements: PropTypes.bool.isRequired,
  hasNationalIdOnLoad: PropTypes.bool.isRequired,
  hasOrganizationOnLoad: PropTypes.bool.isRequired,
  stepOneCompleted: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
};

export default compose(
  connect((state) => ({
    companyList: state.application.companyList,
    currentStep: state.application.currentStep,
    hasLoadedCompanyEngagements: state.application.hasLoadedCompanyEngagements,
    hasNationalIdOnLoad: state.application.hasNationalIdOnLoad,
    hasOrganizationOnLoad: state.application.hasOrganizationOnLoad,
    stepOneCompleted: state.application.stepOneCompleted,
    loanDetails: state.application.loanDetails,
    name: state.application.name,
    isUpdating: state.application.isUpdating,
  }))
)(LoanDetails);
