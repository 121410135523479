import {
  call,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  BANK_ACCOUNT_FETCH_PROVIDER_LOGIN,
  BANK_ACCOUNT_FETCH_PROVIDERS,
  BANK_ACCOUNT_FETCH_HISTORY,
} from '../constants/bankAccounts'
import {
  setLoginUrl,
  setBankAccountProviders,
} from '../actions/bankAccountsActions'
import { logError } from '../actions/ui'

function* bankAccountsWatcher(action) {
  let res
  try {
    switch (action.type) {
      case BANK_ACCOUNT_FETCH_PROVIDER_LOGIN:
        res = yield call(
          axios.post,
          `${process.env.BANKACCOUNTGATEWAY_API}/v1/authentication`,
          action.payload,
        )
        yield put(setLoginUrl(res.data))
        break
      case BANK_ACCOUNT_FETCH_HISTORY:
        yield fork(() => {
          axios.post(
            `${process.env.BANKACCOUNTGATEWAY_API}/v1/bank-account-snapshots`,
            action.payload,
          )
        })
        break
      case BANK_ACCOUNT_FETCH_PROVIDERS:
        res = yield call(
          axios.get,
          `${process.env.BANKACCOUNTGATEWAY_API}/v1/providers?countryCode=${action.countryCode}`,
        )

        yield put(setBankAccountProviders(res.data))
        break
      default:
    }
  } catch (err) {
    yield put(logError(err))
  }
}

function* bankAccountsSaga() {
  yield takeEvery([
    BANK_ACCOUNT_FETCH_PROVIDER_LOGIN,
    BANK_ACCOUNT_FETCH_PROVIDERS,
    BANK_ACCOUNT_FETCH_HISTORY,
  ], bankAccountsWatcher)
}

export default bankAccountsSaga
