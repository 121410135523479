import styled from 'styled-components'
import mq from 'helpers/mq'

const ApplicationThankYouContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 100px;
  position: relative;
  width: 100%;

  ${mq.tablet`
    max-width: 520px;
    margin: 0 auto;
  `};
`

const ApplicationThankYouBox = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 2px 2px rgba(128, 128, 128, .25);
  padding: 64px 24px 24px;
  position: relative;
  text-align: center;

  ${mq.largeDesktop`
    background-color: #f8f8f8;
  `}

  p {
    margin: 1rem 0;
  }
`

const ApplicationThankYouBoxIcon = styled.div`
  align-items: center;
  background-color: #42D984;
  border-radius: 50%;
  display: flex;
  height: 64px;
  justify-content: center;
  left: calc(50% - 32px);
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 64px;

  i {
    color: #fff;
  }
`

const ApplicationThankYouContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px 16px 48px;
  width: 100%;

  a {
    :last-child {
      margin: 16px 0;
    }
  }
`

const ApplicationThankYouButtonContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-top: 50px;

  ${mq.tablet`
    align-items: center;
  `};
`

const ApplicationThankYouTitle = styled.h3`
  font-size: 1.125rem;
`

const ApplicationThankYouDescription = styled.p`
  font-size: .875rem;
  margin-bottom: 50px;
`

export {
  ApplicationThankYouBox,
  ApplicationThankYouBoxIcon,
  ApplicationThankYouContainer,
  ApplicationThankYouContent,
  ApplicationThankYouButtonContainer,
  ApplicationThankYouTitle,
  ApplicationThankYouDescription,
}
