import { takeEvery } from 'redux-saga/effects'
import * as Sentry from '@sentry/browser'
import { UI_LOG_ERROR } from '../constants/ui'

function uiWatcher(action) {
  const { type, ...payload } = action

  switch (type) {
    case UI_LOG_ERROR:
      if (process.env.NODE_ENV === 'development') {
        console.error(payload.error)
      }

      Sentry.captureException(payload.error)
      break
    default: break
  }
}

function* uiSaga() {
  yield takeEvery([
    UI_LOG_ERROR,
  ], uiWatcher)
}

export default uiSaga
