import { SET_LOCALE } from '../constants/intl'
import initialState from '../initialState'

const intlReducer = (state = initialState.intl, action) => {
  switch (action.type) {
    case SET_LOCALE:
      return {
        locale: action.locale,
      }
    default:
      return state
  }
}

export default intlReducer
