import styled, { css } from 'styled-components'

export const FaqContainer = styled.div`

`

export const FaqItems = styled.div`

`

export const FaqItemToggle = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 3px;
  transform: rotate(45deg);
  transform-origin: center;
  transition: transform .3s ease-in-out;

  i {
    font-size: .625rem;
  }
`

export const FaqQuestion = styled.div`
  cursor: pointer;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding-bottom: 10px;
  padding-right: 20px;
`

export const FaqAnswer = styled.div`
  color: #000;
  font-size: .875rem;
  height: 0;
  line-height: 1.5rem;
  overflow: hidden;
  transition: height .3s ease-in-out;
`

export const FaqTitle = styled.h3`
  font-size: 1.125rem;
`

export const FaqItem = styled.div`
  border-bottom: 1px solid #808080;
  margin-top: 20px;
  padding-bottom: 10px;
  position: relative;

  ${props => props.isOpen && css`
    ${FaqItemToggle} {
      transform: rotate(0);
    }
  `}
`
