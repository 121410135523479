import React, { useEffect, useState } from "react";
import useTranslate from "hooks/useTranslate";
import { Helmet } from "react-helmet-async";

import { Button, Icon, ThemeProvider, themes } from "@qred/ui-components";

import Navigation from "components/Navigation";
import Faq from "./components/Faq";

import {
  ApplicationThankYouBox,
  ApplicationThankYouBoxIcon,
  ApplicationThankYouContainer,
  ApplicationThankYouContent,
  ApplicationThankYouTitle,
  ApplicationThankYouDescription,
  ApplicationThankYouButtonContainer,
} from "./ApplicationThankYou.css";

const confetti = require("canvas-confetti");

const ApplicationThankYou = () => {
  const t = useTranslate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
    const end = Date.now() + 3 * 1000;
    const myConfetti = confetti.create();
    const confettiInterval = setInterval(() => {
      if (Date.now() > end) {
        return clearInterval(confettiInterval);
      }

      myConfetti({
        startVelocity: 30,
        spread: 180,
        ticks: 60,
        shapes: ["square"],
        origin: {
          x: Math.random(),
          y: Math.random() - 0.2,
        },
      });
      return false;
    }, 400);

    return () => {
      clearInterval(confettiInterval);
    };
  }, []);

  return (
    <ThemeProvider theme={themes.swagnes}>
      <ApplicationThankYouContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex" />
          <title>{t("application.head.title")}</title>
          <meta
            name="description"
            content={t("application.head.description")}
          />
          <link
            rel="canonical"
            href={
              process.env.ENVIRONMENT === "production"
                ? "https://qred.com/"
                : "https://beta.qred.com/"
            }
          />
          <link rel="icon" type="image/png" href="/favicon.png" />
          <meta name="theme-color" content="#42d984" />
        </Helmet>
        <Navigation toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} />
        <ApplicationThankYouBox>
          <ApplicationThankYouBoxIcon>
            <Icon name="checked" />
          </ApplicationThankYouBoxIcon>
          <ApplicationThankYouTitle>
            {t("applicationThankYou.title")}
          </ApplicationThankYouTitle>
          <ApplicationThankYouDescription>
            {t("applicationThankYou.description")}
          </ApplicationThankYouDescription>
        </ApplicationThankYouBox>
        <ApplicationThankYouContent>
          <Faq />
          <ApplicationThankYouButtonContainer>
            <Button secondary to={t("links.home.url")}>
              {t("applicationThankYou.button.home")}
            </Button>
            <Button tertiary to={t("links.myPages.url")}>
              {t("applicationThankYou.button.myPages")}
            </Button>
          </ApplicationThankYouButtonContainer>
        </ApplicationThankYouContent>
      </ApplicationThankYouContainer>
    </ThemeProvider>
  );
};

export default ApplicationThankYou;
