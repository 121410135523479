import initialState from 'store/initialState'
import {
  UI_APP_LOADED,
  UI_MODAL,
  UI_SET_HEIGHT,
} from '../constants/ui'

const uiReducer = (state = initialState.ui, action) => {
  const { type, ...payload } = action

  switch (type) {
    case UI_APP_LOADED:
      return {
        ...state,
        isAppLoaded: true,
      }
    case UI_MODAL:
      return {
        ...state,
        modal: payload.modal,
      }
    case UI_SET_HEIGHT:
      return {
        ...state,
        height: payload.height,
      }
    default:
      return state
  }
}

export default uiReducer
