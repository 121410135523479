import {
  APPLICATION_FETCH_APPLICATION,
  APPLICATION_FETCH_COMPANY_ENGAGEMENTS,
  APPLICATION_FETCHED,
  APPLICATION_FINALIZE_APPLICATION,
  APPLICATION_FINALIZE_FAILED,
  APPLICATION_FINALIZE_SUCCESS,
  APPLICATION_HANDLE_NEXT_STEP,
  APPLICATION_INIT_APPLICATION,
  APPLICATION_NAVIGATE,
  APPLICATION_SET_COMPANY_INFO,
  APPLICATION_SET_COMPANY_ENGAGEMENTS,
  APPLICATION_SET_ID,
  APPLICATION_SET_INFO,
  APPLICATION_SET_LOAN_DETAILS,
  APPLICATION_SET_NAME,
  APPLICATION_SET_STATUS,
  APPLICATION_SET_STEP,
  APPLICATION_UPDATE_APPLICATION,
  APPLICATION_UPLOAD_DOCUMENTS,
  APPLICATION_UPDATE_SUCCESS,
  APPLICATION_UPDATE_FAILED,
  APPLICATION_SET_RLC,
  APPLICATION_APPLICATION_LOADED,
} from '../constants/application'

export const setApplicationId = id => ({
  type: APPLICATION_SET_ID,
  applicationId: id,
})

export const setApplicationStatus = status => ({
  type: APPLICATION_SET_STATUS,
  status,
})

export const setApplicationName = data => ({
  type: APPLICATION_SET_NAME,
  firstName: data.firstName,
  lastName: data.lastName,
})

export const setCurrentStep = step => ({
  type: APPLICATION_SET_STEP,
  step,
})

export const setLoanDetails = details => ({
  type: APPLICATION_SET_LOAN_DETAILS,
  ...details,
})

export const setCompanyInfo = info => ({
  type: APPLICATION_SET_COMPANY_INFO,
  ...info,
})

export const setApplication = info => ({
  type: APPLICATION_SET_INFO,
  ...info,
})

export const initApplication = () => ({
  type: APPLICATION_INIT_APPLICATION,
})

export const applicationLoaded = ({ hasNationalIdOnLoad, hasOrganizationOnLoad }) => ({
  type: APPLICATION_APPLICATION_LOADED,
  hasNationalIdOnLoad,
  hasOrganizationOnLoad,
})

export const fetchApplication = () => ({
  type: APPLICATION_FETCH_APPLICATION,
})

export const applicationFetchted = () => ({
  type: APPLICATION_FETCHED,
})

export const finalizeApplication = () => ({
  type: APPLICATION_FINALIZE_APPLICATION,
})

export const applicationFinalizeFailed = () => ({
  type: APPLICATION_FINALIZE_FAILED,
})

export const applicationFinalizeSuccess = () => ({
  type: APPLICATION_FINALIZE_SUCCESS,
})

export const updateApplication = () => ({
  type: APPLICATION_UPDATE_APPLICATION,
})

export const applicationUpdateFailed = () => ({
  type: APPLICATION_UPDATE_FAILED,
})

export const applicationUpdateSuccess = () => ({
  type: APPLICATION_UPDATE_SUCCESS,
})

export const applicationSetRLC = isRLC => ({
  type: APPLICATION_SET_RLC,
  isRLC,
})

export const fetchCompanyEngagements = () => ({
  type: APPLICATION_FETCH_COMPANY_ENGAGEMENTS,
})

export const setCompanyEngagements = data => ({
  type: APPLICATION_SET_COMPANY_ENGAGEMENTS,
  list: data,
})

export const uploadDocuments = () => ({
  type: APPLICATION_UPLOAD_DOCUMENTS,
})

export const navigateToStep = step => ({
  type: APPLICATION_NAVIGATE,
  step,
})

export const handleNextStep = () => ({
  type: APPLICATION_HANDLE_NEXT_STEP,
})
