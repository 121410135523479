import applicationReducer from './application'
import authReducer from './auth'
import bankAccountsReducer from './bankAccounts'
import intlReducer from './intl'
import uiReducer from './ui'

export default {
  applicationReducer,
  authReducer,
  bankAccountsReducer,
  intlReducer,
  uiReducer,
}
