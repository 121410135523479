import { all } from 'redux-saga/effects'
import applicationSaga from './application'
import authSaga from './auth'
import bankAccountsSaga from './bankAccounts'
import uiSaga from './ui'

export default function* rootSaga() {
  yield all([
    applicationSaga(),
    authSaga(),
    bankAccountsSaga(),
    uiSaga(),
  ])
}
