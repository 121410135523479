import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Icon } from '@qred/ui-components'
import useTranslate from 'hooks/useTranslate'
import { setModal } from 'store/actions/ui'

import {
  ApplicationErrorActions,
  ApplicationErrorClose,
  ApplicationErrorContainer,
  ApplicationErrorContent,
  ApplicationErrorIcon,
} from './ApplicationError.css'

const ApplicationError = ({ isFixed }) => {
  const t = useTranslate()
  const dispatch = useDispatch()
  const applicationId = useSelector(state => state.application.applicationId) || ''

  const closeModal = () => dispatch(setModal(''))

  const handleBgClick = e => {
    if (!isFixed && e.currentTarget === e.target) {
      closeModal()
    }
  }

  return (
    <ApplicationErrorContainer onClick={handleBgClick}>
      <ApplicationErrorContent>
        {!isFixed && (
          <ApplicationErrorClose onClick={closeModal}>
            <Icon name="close" />
          </ApplicationErrorClose>
        )}
        <ApplicationErrorIcon>
          <Icon name="close" />
        </ApplicationErrorIcon>
        <h4>{t('applicationError.title')}</h4>
        <p>
          {t('applicationError.text', {
            phoneLink: <strong><a href={`tel:${t('contact.phone.raw')}`}>{t('contact.phone.formatted')}</a></strong>,
            emailLink: <strong><a href={`mailto:${t('contact.email.raw')}`}>{t('contact.email.formatted')}</a></strong>,
          })}
        </p>
        <p>{t('applicationError.code', { errorCode: <strong>{applicationId.substring(2, 10)}</strong> })}</p>
        {!isFixed && (
          <ApplicationErrorActions>
            <Button secondary onClick={closeModal}>{t('applicationError.button')}</Button>
          </ApplicationErrorActions>
        )}
      </ApplicationErrorContent>
    </ApplicationErrorContainer>
  )
}

ApplicationError.propTypes = {
  isFixed: PropTypes.bool,
}

ApplicationError.defaultProps = {
  isFixed: false,
}

export default ApplicationError
