import styled from 'styled-components'

const ApplicationErrorContainer = styled.div`
  background-color: rgba(0, 0, 0, .8);
  bottom: 0;
  left: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9000;
`

const ApplicationErrorIcon = styled.div`
  align-items: center;
  background-color: #ED4848;
  border-radius: 50%;
  display: flex;
  height: 64px;
  justify-content: center;
  left: calc(50% - 32px);
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 64px;

  i {
    &:before {
      color: #fff;
    }
  }
`

const ApplicationErrorContent = styled.div`
  align-content: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .125);
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  padding: 68px 28px 48px;
  position: relative;
  text-align: center;
  width: 100%;
`

const ApplicationErrorActions = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
`

const ApplicationErrorClose = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 8px;
  position: absolute;
  right: 4px;
  top: 4px;

  i {
    font-size: 1rem;
  }
`

export {
  ApplicationErrorActions,
  ApplicationErrorClose,
  ApplicationErrorContainer,
  ApplicationErrorContent,
  ApplicationErrorIcon,
}
