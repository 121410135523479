import {
  BANK_ACCOUNT_FETCH_PROVIDER_LOGIN,
  BANK_ACCOUNT_FETCH_PROVIDERS,
  BANK_ACCOUNT_FETCH_HISTORY,
  BANK_ACCOUNT_SET_LOGIN_URL,
  BANK_ACCOUNT_SET_PROVIDERS,
  BANK_ACCOUNT_SET_SEARCH_VALUE,
} from '../constants/bankAccounts'

export const fetchProviderLogin = data => ({
  type: BANK_ACCOUNT_FETCH_PROVIDER_LOGIN,
  payload: data,
})

export const fetchProviders = countryCode => ({
  type: BANK_ACCOUNT_FETCH_PROVIDERS,
  countryCode,
})

export const fetchBankAccountHistory = data => ({
  type: BANK_ACCOUNT_FETCH_HISTORY,
  payload: data,
})

export const setLoginUrl = url => ({
  type: BANK_ACCOUNT_SET_LOGIN_URL,
  url,
})

export const setBankAccountProviders = providers => ({
  type: BANK_ACCOUNT_SET_PROVIDERS,
  providers,
})

export const setSearchValue = searchValue => ({
  type: BANK_ACCOUNT_SET_SEARCH_VALUE,
  searchValue,
})
