import {
  UI_APP_LOADED,
  UI_LOG_ERROR,
  UI_MODAL,
  UI_SET_HEIGHT,
  UI_FATAL_ERROR,
} from '../constants/ui'

export const setModal = modal => ({
  type: UI_MODAL,
  modal,
})

export const setFatalError = error => ({
  type: UI_FATAL_ERROR,
  error,
})

export const initApp = () => ({
  type: UI_APP_LOADED,
})

export const setContainerHeight = height => ({
  type: UI_SET_HEIGHT,
  height,
})

export const logError = err => ({
  type: UI_LOG_ERROR,
  err,
})
