import React from 'react'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { renderRoutes } from 'react-router-config'
import { IntlProvider } from 'react-intl'
import { HelmetProvider } from 'react-helmet-async'
import { configureStore } from 'store'
import { setApplicationName } from 'store/actions/application'
import routes from './routes'
import {
  initI18n,
  LOCALE_MASK,
  DEFAULT_LOCALE,
} from './helpers/i18n'
import 'styles/App.scss'
import AppContainer from './components/AppContainer'

const tagManagerArgs = {
  gtmId: process.env.GOOGLE_TAG_MANAGER,
}
const qs = require('qs')

function App() {
  const [store, history] = configureStore()
  const queryString = qs.parse(history.location.search.substring(1))
  store.dispatch(setApplicationName(queryString))

  const splitPath = history.location.pathname.split('/')
  const locale = splitPath[1]
  const i18nConfig = initI18n(locale, store)
  const maskedLocale = LOCALE_MASK[locale] || LOCALE_MASK[DEFAULT_LOCALE]

  TagManager.initialize(tagManagerArgs)

  return (
    <IntlProvider
      locale={maskedLocale}
      messages={i18nConfig.messages[maskedLocale]}
      textComponent={React.Fragment}
    >
      <Provider store={store}>
        <HelmetProvider>
          <ConnectedRouter history={history}>
            <AppContainer>
              {renderRoutes(routes)}
            </AppContainer>
          </ConnectedRouter>
        </HelmetProvider>
      </Provider>
    </IntlProvider>
  )
}

export default App
