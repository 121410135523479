import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import breakpoints from 'constants/breakpoints'
import useTranslate from 'hooks/useTranslate'
import {
  Header,
  Icon,
  MobileDrawer,
} from '@qred/ui-components'
import {
  NavigationContainer,
  MobileMenu,
  MobileMenuItem,
  DesktopMenu,
  DesktopMenuItem,
  DrawerMenu,
  DrawerMenuItem,
} from './Navigation.css'

const Navigation = ({
  toggleDrawer,
  currentStep,
  drawerOpen,
}) => {
  const t = useTranslate()
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.largeDesktop}px)`,
  })

  return (
    <>
      <Header
        logoUrl="https://static.qred.com/sv-se/bilder/logo-mobile-green.svg"
        backgroundColor={isDesktop ? '#fff' : '#F8F8F8'}
        to={(currentStep === -1) ? t('links.home.url') : undefined}
      >
        <NavigationContainer>
          <DesktopMenu>
            <DesktopMenuItem href={t('links.home.url')}>
              {t('links.home.label')}
            </DesktopMenuItem>
            <DesktopMenuItem href={t('links.aboutUs.url')}>
              {t('links.aboutUs.label')}
            </DesktopMenuItem>
            <DesktopMenuItem href={t('links.faq.url')}>
              {t('links.faq.label')}
            </DesktopMenuItem>
            <DesktopMenuItem href={t('links.myPages.url')}>
              {t('links.myPages.label')}
            </DesktopMenuItem>
          </DesktopMenu>
          <MobileMenu>
            <MobileMenuItem>
              <Icon name="user" />
            </MobileMenuItem>
            <MobileMenuItem onClick={toggleDrawer}>
              <Icon name="mobile-menu" />
            </MobileMenuItem>
          </MobileMenu>
        </NavigationContainer>
      </Header>
      <MobileDrawer
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <DrawerMenu>
          <DrawerMenuItem href={t('links.home.url')}>
            {t('links.home.label')}
          </DrawerMenuItem>
          <DrawerMenuItem href={t('links.aboutUs.url')}>
            {t('links.aboutUs.label')}
          </DrawerMenuItem>
          <DrawerMenuItem href={t('links.faq.url')}>
            {t('links.faq.label')}
          </DrawerMenuItem>
          <DrawerMenuItem href={t('links.myPages.url')}>
            {t('links.myPages.label')}
          </DrawerMenuItem>
        </DrawerMenu>
      </MobileDrawer>
    </>
  )
}

Navigation.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
}

export default compose(
  connect(
    state => ({
      currentStep: state.application.currentStep,
    }),
  ),
)(Navigation)
