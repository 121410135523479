import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import 'styles/App.scss'
import App from './App'

if (process.env.NODE_ENV !== 'test') {
  Sentry.init({
    dsn: 'https://ce3dacc813a14d72969ae04d24c5895e@sentry.io/1548863',
    environment: process.env.NODE_ENV === 'production'
      ? 'production'
      : 'development',
  })
}

ReactDOM.render(
  React.createElement(App),
  document.getElementById('root'),
)
