import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux'
import {
  connectRouter,
  routerMiddleware,
  CALL_HISTORY_METHOD,
} from 'connected-react-router'
import { createBrowserHistory, createMemoryHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import * as Sentry from '@sentry/browser'
import reducers from './reducers'
import initialState from './initialState'
import rootSaga from './sagas'

export const configureStore = () => {
  const history = typeof window !== 'undefined' ? createBrowserHistory() : createMemoryHistory()

  const middleware = []

  // Add saga middleware
  const sagaMiddleware = createSagaMiddleware({
    onError: err => Sentry.captureException(err),
  })
  middleware.push(sagaMiddleware)

  // Add custom intl middleware to handle route transformations
  const intlMiddleware = store => next => action => {
    if (action.type === CALL_HISTORY_METHOD) {
      const regex = /^\/(([a-zA-Z]{2})(\/?|\/.+)|([a-zA-Z]{2}-[a-zA-Z]{2})(\/?|\/.+))$/
      const { locale } = store.getState().intl
      const [pathname] = action.payload.args
      if (!regex.test(pathname)) {
        // eslint-disable-next-line no-param-reassign
        action.payload.args[0] = `/${locale}${pathname}`
      }
    }

    return next(action)
  }
  middleware.push(intlMiddleware)

  // Add router middleware
  // eslint-disable-next-line new-cap
  middleware.push(new routerMiddleware(history))

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    combineReducers({
      auth: reducers.authReducer,
      bankAccounts: reducers.bankAccountsReducer,
      intl: reducers.intlReducer,
      ui: reducers.uiReducer,
      router: connectRouter(history),
      application: reducers.applicationReducer,
    }),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
    ),
  )

  sagaMiddleware.run(rootSaga)

  return [store, history]
}

export default {
  configureStore,
}
