import styled from 'styled-components'
import mq from 'helpers/mq'

const ApplicationFinishedContainer = styled.div`
  background-color: #F8F8F8;
  bottom: 0;
  left: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 90;

  ${mq.largeDesktop`
    background-color: #fff;
  `};
`

const ApplicationFinishedIcon = styled.div`
  align-items: center;
  background-color: #42D984;
  border-radius: 50%;
  display: flex;
  height: 64px;
  justify-content: center;
  left: calc(50% - 32px);
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 64px;

  i {
    &:before {
      color: #fff;
    }
  }
`

const ApplicationFinishedContent = styled.div`
  align-content: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .125);
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  padding: 68px 28px 48px;
  position: relative;
  text-align: center;
  width: 100%;

  ${mq.largeDesktop`
    background-color: #f8f8f8;
  `};
`

const ApplicationFinishedActions = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
`

export {
  ApplicationFinishedActions,
  ApplicationFinishedContainer,
  ApplicationFinishedContent,
  ApplicationFinishedIcon,
}
