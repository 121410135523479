import styled, { css } from 'styled-components'

const ResponseMessageIconWrapper = styled.div`
  align-items: center;
  background-color: #4C53FF;
  border-radius: 50%;
  display: flex;
  height: 63px;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 63px;

  i {
    &:before {
      color: #fff;
    }
  }
`

const ResponseMessageBox = styled.div`
  align-content: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .125);
  display: flex;
  flex-direction: column;
  margin-top: 31.5px;
  padding: 68px 28px 48px;
  width: 100%;
`

const ResponseMessageTitle = styled.h4`
  margin-bottom: 20px;
  text-align: center;
`

const ResponseMessageText = styled.p`
  text-align: center;
`

const ResponseMessageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  ${props => props.type === 'error' && css`
    ${ResponseMessageIconWrapper} {
      background-color: #ED4848;
    }
  `}

    ${props => props.type === 'success' && css`
      ${ResponseMessageIconWrapper} {
        background-color: #42D984;
      }
    `}
`

export {
  ResponseMessageContainer,
  ResponseMessageIconWrapper,
  ResponseMessageBox,
  ResponseMessageTitle,
  ResponseMessageText,
}
