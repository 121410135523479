import styled from 'styled-components'

const NotFoundContainer = styled.div`
  background-color: #F8F8F8;
  min-height: 100vh;
`

const NotFoundContent = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 60px 24px;
`

const NotFoundTitle = styled.h1`

`

const NotFoundSubTitle = styled.p`

`

const NotFoundBackLink = styled.a`
  color: #4c5cff;
  text-decoration: underline;
`

export {
  NotFoundContainer,
  NotFoundContent,
  NotFoundTitle,
  NotFoundSubTitle,
  NotFoundBackLink,
}
