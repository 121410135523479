const mapValidLanguageNAG = {
  se: 'sv',
  'en-se': 'en',
}

export {
  mapValidLanguageNAG,
}

export default mapValidLanguageNAG
